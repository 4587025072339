.typeOfRequirements {
  &.bLoans {
    background-color: #d2e4ff;
  }
  &.dFinance {
    background-color: #c2daff;
  }
  &.sRepo {
    background-color: #ffdccc;
  }
  &.reSources {
   
    background-color: #e1d8f1;
  }
  width: 60%;
  margin: 2rem auto;
  .header {
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }
  .content {
    &.bLoans {
      border-bottom: 3px solid #4caf50;
    }
    &.dFinance {
      border-bottom: 3px solid #002d72;
    }
    &.sRepo {
      border-bottom: 3px solid #ff7233;
    }
    &.reSources {
      border-bottom: 3px solid #673ab7;
    }
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem;
    p{
      ul{
        display: flex;
    flex-direction: column;
   
    align-items: flex-start;
      }
    }
    .left {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

      .leftHead {
        font-weight: bold;
      }
      .callText {
        font-weight: bold;
      }
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        &.bLoans {
          color: #4caf50;
          border: 1px solid #4caf50;
        }
        &.dFinance {
          color: #002d72;
          border: 1px solid #002d72;
        }
        &.sRepo {
          color: #ff7233;
          border: 1px solid #ff7233;
        }
        &.reSources {
          color: #673ab7;
          border: 1px solid #673ab7;
        }
        padding: 0.3rem 0.5rem;
        font-weight: bold;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .link {
        text-decoration: none;
        color: white;
        padding: 0.3rem 0.5rem;

        &.bLoans {
          background-color: #4caf50;
        }
        &.dFinance {
          background-color: #002d72;
        }
        &.sRepo {
          background-color: #ff7233;
        }
        &.reSources {
          background-color: #673ab7;
        }
      }
    }
    .right {
      .rightHead {
        margin-left: -15px;
        margin-bottom: 5px;
      }
      .list {
        li {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media screen and (max-width:900px) {
  .typeOfRequirements {
    &.bLoans {
      background-color: #d2e4ff;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    width: 90%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.5rem 1rem;
      font-size: 1.1rem;
    }
    .content {
      &.bLoans {
        border-bottom: 3px solid #4caf50;
      }
      &.dFinance {
        border-bottom: 3px solid #002d72;
      }
      &.sRepo {
        border-bottom: 3px solid #ff7233;
      }
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      padding: 2rem;
      .left {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  
        .leftHead {
          font-weight: bold;
        }
        .callText {
          font-weight: bold;
        }
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          &.bLoans {
            color: #4caf50;
            border: 1px solid #4caf50;
          }
          &.dFinance {
            color: #002d72;
            border: 1px solid #002d72;
          }
          &.sRepo {
            color: #ff7233;
            border: 1px solid #ff7233;
          }
          padding: 0.3rem 0.5rem;
          font-weight: bold;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .link {
          text-decoration: none;
          color: white;
          padding: 0.3rem 0.5rem;
  
          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
          &.sRepo {
            background-color: #ff7233;
          }
        }
      }
      .right {
        .rightHead {
          margin-left: -15px;
          margin-bottom: 5px;
        }
        .list {
          li {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width:500px) {
  .typeOfRequirements {
    &.bLoans {
      background-color: #d2e4ff;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    width: 95%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.5rem 1rem;
      font-size: 1.1rem;
    }
    .content {
      &.bLoans {
        border-bottom: 3px solid #4caf50;
      }
      &.dFinance {
        border-bottom: 3px solid #002d72;
      }
      &.sRepo {
        border-bottom: 3px solid #ff7233;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      // text-align: center;
      padding: 2rem 1.2rem;
      .left {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    
  
        .leftHead {
          font-weight: bold;
        }
        .callText {
          font-weight: bold;
        }
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          &.bLoans {
            color: #4caf50;
            border: 1px solid #4caf50;
          }
          &.dFinance {
            color: #002d72;
            border: 1px solid #002d72;
          }
          &.sRepo {
            color: #ff7233;
            border: 1px solid #ff7233;
          }
          padding: 0.3rem 0.5rem;
          font-weight: bold;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .link {
          text-decoration: none;
          color: white;
          padding: 0.3rem 0.5rem;
  
          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
          &.sRepo {
            background-color: #ff7233;
          }
        }
      }
      .right {
        .rightHead {
          margin-left: -15px;
          margin-bottom: 5px;
        }
        .list {
          li {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}