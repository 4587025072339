.question {
  // border: 1px solid black;
  padding: 0.5rem 1.5rem;
  border-radius: 20px 20px 20px 0px;
  width: fit-content;
  align-self: flex-start;
  background-color: #4caf50;
  color: white;
  position: relative;
  width: 80%;
  box-sizing: border-box;
  min-height: 80px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 20px;
  // justify-content: center;

  &.section1 {
    background-color: #ff7232;
  }
  &.section2 {
    background-color: #673ab7;
  }
  &.section3 {
    background-color: #4caf50;
  }
  &.section4 {
    background-color: #002d72;
  }
  &.section5 {
    background-color: #f8ab17;
  }

  &::before {
    background-image: url("../../../../assets/images/sum.png");
    // background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 35px !important;
    content: "" !important;
    height: 68px;
    left: -4% !important;
    position: absolute !important;
    top: -20px !important;
    width: 68px;
  }

  &::after {
    background-image: url("../../../../assets/images/Green-traingle.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: -18px;
    content: "";
    height: 38px;
    left: 0px;
    position: absolute;
    width: 38px;
  }

  &.section1::after {
    background-image: url("../../../../assets/images/Orange-traingle.svg");
  }
  &.section2::after {
    background-image: url("../../../../assets/images/Purple-traingle.svg");
  }
  &.section3::after {
    background-image: url("../../../../assets/images/Green-traingle.svg");
  }
  &.section4::after {
    background-image: url("../../../../assets/images/Dark_Blue-traingle.svg");
  }
  &.section5::after {
    background-image: url("../../../../assets/images/yellow-traingle.svg");
  }
}
