.tooltip {
  padding: 1rem;
  background-color: #2463eb;
  color: white;
  position: absolute;
  top: 2rem;
  right: -4rem;
  border-radius: 5px;
  z-index: 1;
  font-size: small;

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: 60%;
    right: 50%;
    height: 0;
    width: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #2463eb;
    border-left: 8px solid transparent;
  }

  ul {
    list-style: none;

    li {
      margin: 0.8rem 0;
      color: white;
    }
  }
}
