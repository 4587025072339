.newsLetterSignUp {
  .loadingContainer {
    .loadingImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }
  }
  .newsLetterBgPrimary {
    // max-width: 1280px;
    margin: 2rem 0;
  }
  .newsLetterBgPrimary .newsLetterContainer {
    // margin: 0px 1rem 0px 1rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #ffdccc;
    // background-image: linear-gradient(to top right, #4e54c8, #8f94fb);
  }
  @media (min-width: 640px) {
    .newsLetterBgPrimary .newsLetterContainer {
      // margin: 0px 2rem 0px 2rem;
    }
  }
  .newsLetterBgPrimary .newsLetterContainer .newsletter {
    max-width: 36rem;
    margin: 0px auto 0px auto;
  }
  .newsLetterBgPrimary .newsLetterContainer .newsletter .newsLetterHeader {
    text-align: center;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterHeader
    > *
    + * {
    margin-top: 1rem;
  }
  .newsLetterBgPrimary .newsLetterContainer .newsletter .newsLetterHeader h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    color: black;
    font-weight: 600;
  }
  .newsLetterBgPrimary .newsLetterContainer .newsletter .newsLetterHeader p {
    color: black;
    line-height: 1.625;
  }
  .newsLetterBgPrimary .newsLetterContainer .newsletter .newsLetterFooter {
    margin-top: 1.25rem;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .newsLetterForm {
    border-radius: 0.375rem;
    max-width: 28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 0px auto;
    padding: 0.25rem;
    background-color: #fff;
    gap: 1rem;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .newsLetterForm
    .input {
    color: #6b7280;
    padding: 0.625rem;
    width: 100%;
    outline: none;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .newsLetterForm
    .submitBtn {
    padding: 0.625rem;
    border-radius: 0.375rem;
    // box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    color: white;
    background-color: #ff7233;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .newsLetterForm
    .submitBtn:focus {
    box-shadow: none;
  }
  @media (min-width: 640px) {
    .newsLetterBgPrimary
      .newsLetterContainer
      .newsletter
      .newsLetterFooter
      .newsLetterForm
      .submitBtn {
      padding: 0.625rem 1.25rem 0.625rem 1.25rem;
      border: 1px solid #ff7233;
    }
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .pNote {
    margin: 0.75rem auto 0px auto;
    text-align: center;
    max-width: 32rem;
    font-size: 15px;
    color: black;
  }
  .newsLetterBgPrimary
    .newsLetterContainer
    .newsletter
    .newsLetterFooter
    .pNote
    .link {
    text-decoration: none;
    color: black;
  }
}
