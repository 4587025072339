.contactComponent {
  padding: 0.5rem 2rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  .top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    align-items: center;

    .left {
      .header {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        font-weight: bolder;
        text-align: center;
        font-family: "Nunito", sans-serif;
      }
      .topText,
      .midText,
      .bottomText {
        padding-bottom: 1rem;
        width: 85%;
        margin: auto;
        text-align: justify;
        line-height: 1.5rem;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 2rem;
      svg {
        width: 400px;
        height: 400px;
      }
    }
    border: 1px black;
  }

  .links {
    width: 90%;
    padding: 0rem 2rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    .contactLink {
      // width: fit-content;
      text-decoration: none;
      text-align: center;
      padding: 0.5rem 1rem;
      // background-color: #4caf50;
      // border: 1px solid #4caf50;
      &:hover {
        background-color: white;
        // color: #4caf50;
      }
      color: black;
      &.bLoans {
        background-color: #c9e7cb;
        border: 1px solid #c9e7cb;
        &:hover {
          background-color: white;
          // color: #4caf50;
        }
      }
      &.dFinance {
        background-color: #c2daff;
        border: 1px solid #c2daff;
        &:hover {
          background-color: white;
          // color: #002d72;
        }
      }
      &.sRepo {
        background-color: #ffdccc;
        border: 1px solid #ffdccc;
        &:hover {
          background-color: white;
          // color: #ff7233;
        }
      }
      &.reSources {
        background-color: #e1d8f1;
        border: 1px solid #e1d8f1;
        &:hover {
          background-color: white;
          // color: #673ab7;
        }
      }
      &.home1 {
        background-color: #4caf50;
        border: 1px solid #4caf50;
        color: white;
        &:hover {
          background-color: white;
          color: #4caf50;
        }
      }
      &.home2 {
        background-color: #002d72;
        border: 1px solid #002d72;
        color: white;
        &:hover {
          background-color: white;
          color: #002d72;
        }
      }
      &.home3 {
        background-color: #ff7233;
        border: 1px solid #ff7233;
        color: white;
        &:hover {
          background-color: white;
          color: #ff7233;
        }
      }
      &.home4 {
        background-color: #673ab7;
        border: 1px solid #673ab7;
        color: white;
        &:hover {
          background-color: white;
          color: #673ab7;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .contactComponent {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    .top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      align-items: center;
      .left {
        .header {
          font-size: 1.5rem;
          font-weight: bold;
        }
        .topText,
        .midText,
        .bottomText {
          width: 95%;
          margin: auto;
        }
      }
      .right {
      }
    }

    .links {
      width: 90%;
      padding: 0rem 2rem;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
      .contactLink {
        // width: fit-content;
        text-decoration: none;
        padding: 0.5rem 1rem;
        // background-color: #4caf50;
        // border: 1px solid #4caf50;
        &:hover {
          background-color: white;
          // color: #4caf50;
        }
        color: black;
        &.bLoans {
          background-color: #c9e7cb;
          border: 1px solid #c9e7cb;
          &:hover {
            background-color: white;
            // color: #4caf50;
          }
        }
        &.dFinance {
          background-color: #c2daff;
          border: 1px solid #c2daff;
          &:hover {
            background-color: white;
            // color: #002d72;
          }
        }
        &.sRepo {
          background-color: #ffdccc;
          border: 1px solid #ffdccc;
          &:hover {
            background-color: white;
            // color: #ff7233;
          }
        }
        &.reSources {
          background-color: #e1d8f1;
          border: 1px solid #e1d8f1;
          &:hover {
            background-color: white;
            // color: #673ab7;
          }
        }
        &.home1 {
          background-color: #4caf50;
          border: 1px solid #4caf50;
          color: white;
          &:hover {
            background-color: white;
            color: #4caf50;
          }
        }
        &.home2 {
          background-color: #002d72;
          border: 1px solid #002d72;
          color: white;
          &:hover {
            background-color: white;
            color: #002d72;
          }
        }
        &.home3 {
          background-color: #ff7233;
          border: 1px solid #ff7233;
          color: white;
          &:hover {
            background-color: white;
            color: #ff7233;
          }
        }
        &.home4 {
          background-color: #673ab7;
          border: 1px solid #673ab7;
          color: white;
          &:hover {
            background-color: white;
            color: #673ab7;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .contactComponent {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    .top {
      .left {
        border: 1px solid #002d72;
        border-radius: 10px;
        .header {
          font-size: 1.4rem;
          font-weight: bold;
          margin: 1rem 0 3rem 0;
        }
        .topText,
        .midText,
        .bottomText {
          width: 95%;
          margin: 1rem 1rem 1rem 0.5rem;
        }
      }
      .right {
        img{
          width: 300px;
          height: 300px;
        }
      }
    }

    .links {
      width: 90%;
      padding: 0rem 2rem;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
      .contactLink {
        // width: fit-content;
        text-decoration: none;
        padding: 0.5rem 1rem;
        // background-color: #4caf50;
        // border: 1px solid #4caf50;
        &:hover {
          background-color: white;
          // color: #4caf50;
        }
        color: black;
        &.bLoans {
          background-color: #c9e7cb;
          border: 1px solid #c9e7cb;
          &:hover {
            background-color: white;
            // color: #4caf50;
          }
        }
        &.dFinance {
          background-color: #c2daff;
          border: 1px solid #c2daff;
          &:hover {
            background-color: white;
            // color: #002d72;
          }
        }
        &.sRepo {
          background-color: #ffdccc;
          border: 1px solid #ffdccc;
          &:hover {
            background-color: white;
            // color: #ff7233;
          }
        }
        &.reSources {
          background-color: #e1d8f1;
          border: 1px solid #e1d8f1;
          &:hover {
            background-color: white;
            // color: #673ab7;
          }
        }
        &.home1 {
          background-color: #4caf50;
          border: 1px solid #4caf50;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          &:hover {
            background-color: white;
            color: #4caf50;
          }
        }
        &.home2 {
          background-color: #002d72;
          border: 1px solid #002d72;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          &:hover {
            background-color: white;
            color: #002d72;
          }
        }
        &.home3 {
          background-color: #ff7233;
          border: 1px solid #ff7233;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          &:hover {
            background-color: white;
            color: #ff7233;
          }
        }
        &.home4 {
          background-color: #673ab7;
          border: 1px solid #673ab7;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          &:hover {
            background-color: white;
            color: #673ab7;
          }
        }
      }
    }
  }
}
