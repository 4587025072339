.pageNotFound {
  width: 80%;
  margin: 4rem auto;
  p {
    text-align: center;
    margin-bottom: 1rem;
    .icon {
      font-size: 30px;
      position: relative;
      top: 8px;
    }
  }
  .links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    a {
      color: black;
      text-decoration: none;
      padding: 0.3rem 0.75rem;
      background-color: #c2daff;
    }
  }
}
@media screen and (max-width: 1000px) {
  .pageNotFound {
    width: 80%;
    padding-top: 100px;
  }
}
@media screen and (max-width: 500px) {
  .pageNotFound {
    padding-top: 100px;
  }
}
