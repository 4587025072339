.about {
  //   border: 1px solid black;
  width: 80%;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    font-family: "Nunito", sans-serif;
  }
  .subheading {
    margin-bottom: 3rem;
    text-align: justify;
  }
  .aboutItem {
    // border: 1px solid black;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    gap: 4rem;

    &.even {
      flex-direction: row;
    }
    &.odd {
      flex-direction: row-reverse;
    }

    img {
      flex: 1;
      //   width: 100%;
      // border: 1px solid black;
      width: 300px;
      height: 400px;
      border-radius: 10px;
      object-fit: contain;
    }
    .text {
      flex: 1;
      padding-top: 3rem;
      p {
        margin-bottom: 2rem;
        text-align: justify;
        line-height: 1.5;
        letter-spacing: 0.4px;
        padding: 1rem 3rem 0 3rem;
      }
      .title {
        font-size: 1.7rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .cardsHead {
    margin: 2rem 0 2rem 0;
    .head {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
    }
    .subHead {
      text-align: justify;
    }
  }
  .aboutCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
    .card {
      border: 1px solid rgb(226, 206, 206);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      cursor: pointer;
      padding: 1rem;
      &:hover {
        background-color: #ffdccc;
      }
      .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          // border: 5px solid #c1b9b6;
          border-radius: 50%;
          border: 2px solid transparent;
          box-shadow: 0 0 0 3px #d9dceb;
        }
        .personDetails {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
      .middle {
      }
      .bottom {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        .icon {
          font-size: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .about {
    padding-top: 0px;
    width: 90%;
  }
}
@media screen and (max-width: 700px) {
  .about {
    .aboutItem {
      display: flex;
      gap: 1rem;
      &.even {
        flex-direction: column;
        align-items: center;

      }
      &.odd {
        flex-direction: column;
        align-items: center;
      }
      img {
        flex: none;
        width: 300px;
        height: 300px;
        margin: auto;
      }
      .text {
        .title {
          font-size: 1.4rem;
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .about {
    .aboutCards {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
    }
    .cardsHead {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 400px) {
  .about {
    width: 92%;

    .aboutItem {
      img {
        flex: none;
        width: 275px;
        height: 250px;
        margin: auto;
      }
      .text{
        p{
          padding: 1rem;
        }
        

        
      }
    }
  }
}
