.deleteModal {
  .deleteIcon1 {
    border: none;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      .icon {
        color: white;
      }
    }
  }
  .deleteIcon2 {
    border: none;
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    top: -1px;
    .icon {
      position: relative;
      top: 2px;
    }
  }
  .loadingContainer {
    .loadingImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      z-index: 111;
    }
  }
}
