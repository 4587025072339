.newsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b7dfb9;
  border-radius: 2rem;
  padding: 2rem;
  width: 85%;
  margin: 2rem auto;
  &.bLoans {
    background-color: #b7dfb9;
  }
  &.dFinance {
    background-color: #d2e4ff;
  }
  &.sRepo {
    background-color: #ffb999;
  }
  &.reSources {
    background-color: #e1d8f1;
  }
  &.home {
    background-color: #673ab7;
    color: white;
  }

  .left {
    gap: 1rem;
    .topText {
      display: inline-block;
      color: #4caf50;
      background-color: #a6d7a8;
      padding: 0.2rem 0.75rem;
      border-radius: 8px;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      &.bLoans {
        color: #4caf50;
        background-color: #a6d7a8;
      }
      &.dFinance {
        color: #002d72;
        background-color: #c2daff;
      }
      &.sRepo {
        color: #ff7233;
        background-color: #ffdccc;
      }
      &.reSources {
        color: #673ab7;
        background-color: #d1c4e9;
      }
      &.home {
        color: #673ab7;
        background-color: white;
        // &:hover {
        //   background-color: white;
        //   color: #673ab7;
        // }
      }
    }
    .midText {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .bottomText {
      width: 60%;
      font-size: 0.9rem;
    }
  }
  .link {
    text-decoration: none;
    color: white;
    background-color: #4caf50;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    border: 1px solid #4caf50;
    &:hover {
      background-color: white;
      color: #4caf50;
    }
    &.bLoans {
      background-color: #4caf50;
      border: 1px solid #4caf50;
      &:hover {
        background-color: white;
        color: #4caf50;
      }
    }
    &.dFinance {
      background-color: #002d72;
      border: 1px solid #002d72;
      &:hover {
        background-color: white;
        color: #002d72;
      }
    }
    &.sRepo {
      background-color: #ff7233;
      border: 1px solid #ff7233;
      &:hover {
        background-color: white;
        color: #ff7233;
      }
    }
    &.reSources {
      background-color: #673ab7;
      border: 1px solid #673ab7;
      &:hover {
        background-color: white;
        color: #673ab7;
      }
    }
    &.home {
      color: #673ab7;
      border: 1px solid #673ab7;
      background-color: white;
      // &:hover {
      //   background-color: white;
      //   color: #673ab7;
      // }
    }
  }
}
@media screen and (max-width: 900px) {
  .newsContainer {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .newsContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 2rem;
    width: 78%;
    margin: 1rem auto;
    .left {
      .midText {
        font-size: 1.2rem;
      }
      .bottomText {
        width: 80%;
      }
    }
  }
}
