.compareContainer {
  border: 1px solid #c9e7cb;
  border-bottom: 5px solid #4caf50;
  &.bLoans {
    border-bottom: 5px solid #4caf50;
  }
  &.dFinance {
    border-bottom: 5px solid #002d72;
  }
  &.sRepo {
    border-bottom: 5px solid #ff7233;
  }
  &.reSources {
    border-bottom: 5px solid #673ab7;
  }
  width: 60%;
  margin: 2rem auto;
  .compareHead {
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    color: white;
    padding: 0.6rem 1rem;
    font-size: 1.1rem;
  }
  .listBox {
    &.bLoans {
      background-color: #c9e7cb;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    &.reSources {
      background-color: #e1d8f1;
    }
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ul {
      li {
        margin-bottom: 0.2rem;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      strong {
        display: block;
        // margin-bottom: 0.5rem;
      }
    }
    .list {
    }
  }
}
@media screen and (max-width: 900px) {
  .compareContainer {
    border: 1px solid #c9e7cb;
    border-bottom: 5px solid #4caf50;
    &.bLoans {
      border-bottom: 5px solid #4caf50;
    }
    &.dFinance {
      border-bottom: 5px solid #002d72;
    }
    &.sRepo {
      border-bottom: 5px solid #ff7233;
    }
    width: 85%;
    margin: 2rem auto;
    .compareHead {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .listBox {
      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #c2daff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      padding: 2rem 4rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        strong {
          display: block;
          // margin-bottom: 0.5rem;
        }
      }
      .list {
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .compareContainer {
    border: 1px solid #c9e7cb;
    border-bottom: 5px solid #4caf50;
    &.bLoans {
      border-bottom: 5px solid #4caf50;
    }
    &.dFinance {
      border-bottom: 5px solid #002d72;
    }
    &.sRepo {
      border-bottom: 5px solid #ff7233;
    }
    width: 95%;
    margin: 2rem auto;
    .compareHead {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .listBox {
      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #c2daff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      padding: 2rem 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        strong {
          display: block;
          // margin-bottom: 0.5rem;
        }
      }
      .list {
      }
    }
  }
}
