.userProfile {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  position: relative;
  .welComeText{
    font-size: 1.5rem;
  }
  .form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .left {
      .UserBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .imgContainer {
          position: relative;
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            cursor: pointer;
            border: 5px solid #c1b9b6;
            border-radius: 50%;
            border: 2px solid transparent;
            box-shadow: 0 0 0 3px #c1b9b6;
          }
          .editIcon {
            font-size: 1.2rem;
            position: absolute;
            top: 85%;
            left: 90%;
            transform: translate(-50%, -50%);
            padding: 0.4rem;
            border: 1px solid #838ab2;
            background-color: #F1F3FF;;
            border-radius: 50%;
            cursor: pointer;
          }
          &:hover {
            -webkit-filter: brightness(70%);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            -ms-transition: all 1s ease;
            transition: all 1s ease;
            .editIcon {
              display: block;
            }
          }
        }
        .userName {
          .editIcon {
            font-size: 1.2rem;
            cursor: pointer;
            position: relative;
            top: 4px;
          }
          input {
            height: 30px;
            padding-left: 5px;
            box-sizing: border-box;
          }
        }
      }
    }
    .right {
      width: 100%;
      .inputBoxContainer{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 1rem;
        .inputBox {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          margin-bottom: 1.5rem;
          input {
            width: 100%;
            height: 35px;
            padding-left: 5px;
            border-radius: 5px;
            border: 1px solid #838ab2;
            outline: none;

          }
          label {
            font-size: 0.9rem;
            margin-bottom: 0.2rem;
          }
        }
      }
      .inputBox {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        margin-bottom: 1.5rem;
        input {
          width: 100%;
          height: 35px;
          padding-left: 5px;
          box-sizing: border-box;
          border-radius: 5px;
          border: 1px solid #838ab2;
          outline: none;
        }
        label {
          font-size: 0.9rem;
          margin-bottom: 0.2rem;
        }
      }
    
      .saveBtn {
        cursor: pointer;
        background-color: #3A57E8;
        border: none;
        color: white;
        padding: 0.5rem 1rem;
        margin: auto;
        width: 100%;
        border-radius: 5px;
      }
    }
  }
  .loadingContainer {
    .loadingImg {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .userProfile {
    .form {
      width: 70%;
    }
  }
}
@media screen and (max-width: 700px) {
  .userProfile {
    .form {
      width: 90%;
    }
  }
}
@media screen and (max-width: 500px) {
  .userProfile {
    .form {
      width: 100%;
    }
  }
}
