.loanApplication {
  width: 60%;
  margin: 2rem auto;
  .header {
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    color: white;
    padding: 0.6rem 1rem;
    font-size: 1.1rem;
  }
  .content {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    &.bLoans {
      background-color: #c9e7cb;
    }
    &.dFinance {
      background-color: #d2e4ff;
    }
    &.sRepo {
      background-color: #ffede6;
    }
    &.reSources {
      background-color: #e1d8f1;
    }
    padding: 2rem;
    .left {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &.bLoans {
        background-color: #a6d7a8;
      }
      &.dFinance {
        background-color: #c2daff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      &.reSources {
        background-color: #d1c4e9;
      }
      padding: 2rem 1rem;
      height: fit-content;
      .rightHeader {
        font-weight: bold;
        font-size: 1.1rem;
      }
      .rightText {
        text-align: justify;
      }
      .link {
        text-decoration: none;
        color: white;
        &.bLoans {
          background-color: #4caf50;
        }
        &.dFinance {
          background-color: #002d72;
        }
        &.sRepo {
          background-color: #ff7233;
        }
        &.reSources {
          background-color: #673ab7;
        }
        padding: 0.5rem 1.5rem;
        margin: auto;
        border-radius: 5px;
      }
      .callText {
        text-align: center;
        font-size: 0.9rem;
      }
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #4caf50;
        &.bLoans {
          border: 1px solid #4caf50;
        }
        &.dFinance {
          border: 1px solid #002d72;
        }
        &.sRepo {
          border: 1px solid #ff7233;
        }
        &.reSources {
          border: 1px solid #673ab7;
        }
        padding: 0.2rem;

        &.bLoans {
          color: #4caf50;
        }
        &.dFinance {
          color: #002d72;
        }
        &.sRepo {
          color: #ff7233;
        }
        &.reSources {
          color: #673ab7;
        }
        font-weight: bold;
        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .loanApplication {
    width: 85%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .content {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #d2e4ff;
      }
      &.sRepo {
        background-color: #ffede6;
      }
      padding: 2rem;
      .left {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
      .right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &.bLoans {
          background-color: #a6d7a8;
        }
        &.dFinance {
          background-color: #c2daff;
        }
        &.sRepo {
          background-color: #ffdccc;
        }
        padding: 2rem 1rem;
        height: fit-content;
        .rightHeader {
          font-weight: bold;
          font-size: 1.1rem;
        }
        .rightText {
          text-align: justify;
        }
        .link {
          text-decoration: none;
          color: white;
          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
          &.sRepo {
            background-color: #ff7233;
          }
          padding: 0.5rem 1.5rem;
          margin: auto;
          border-radius: 5px;
        }
        .callText {
          text-align: center;
          font-size: 0.9rem;
        }
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #4caf50;
          &.bLoans {
            border: 1px solid #4caf50;
          }
          &.dFinance {
            border: 1px solid #002d72;
          }
          &.sRepo {
            border: 1px solid #ff7233;
          }
          padding: 0.2rem;

          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
          &.sRepo {
            color: #ff7233;
          }
          font-weight: bold;
          img {
            height: 20px;
            width: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .loanApplication {
    width: 95%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #d2e4ff;
      }
      &.sRepo {
        background-color: #ffede6;
      }
      padding: 2rem 1.2rem;
      .left {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
      .right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &.bLoans {
          background-color: #a6d7a8;
        }
        &.dFinance {
          background-color: #c2daff;
        }
        &.sRepo {
          background-color: #ffdccc;
        }
        padding: 2rem 1rem;
        height: fit-content;
        .rightHeader {
          font-weight: bold;
          font-size: 1.1rem;
        }
        .rightText {
          text-align: justify;
        }
        .link {
          text-decoration: none;
          color: white;
          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
          &.sRepo {
            background-color: #ff7233;
          }
          padding: 0.5rem 1.5rem;
          margin: auto;
          border-radius: 5px;
        }
        .callText {
          text-align: center;
          font-size: 0.9rem;
        }
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #4caf50;
          &.bLoans {
            border: 1px solid #4caf50;
          }
          &.dFinance {
            border: 1px solid #002d72;
          }
          &.sRepo {
            border: 1px solid #ff7233;
          }
          padding: 0.2rem;

          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
          &.sRepo {
            color: #ff7233;
          }
          font-weight: bold;
          img {
            height: 20px;
            width: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
