.heroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  background-image: url(../assets/images/hero_image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  // background-size: 90% 67%;
  background-size: 100% auto;
  // max-width: 100%;
  // height: auto;
  padding-bottom: 16rem;
  .top {
    display: flex;
    flex-direction: column;
    .btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 50%;
      grid-gap: 2rem;
      margin: auto;
      margin-bottom: 1.5rem;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1.5rem;
        border-radius: 4px;

        span {
          text-decoration: none;
          // color: white;
          text-align: center;
          cursor: pointer;
        }

        &.loans {
          border: 1px solid #4caf50;
          color: white;
          background: #4caf50;
          &:hover {
            background-color: white;
            color: #4caf50;
            
          }
        }
        &.finance {
          border: 1px solid #002d72;
          color: white;
          background: #002d72;
          &:hover {
            background-color: white;
            color: #002d72;
          }
        }
      }
    }
    .topHead {
      .adivceText {
        padding: 0.3rem 0.75rem;
        font-size: 0.7rem;
        background: #a6d7a8;
        color: #23356d;
        border-radius: 1rem;
        margin-bottom: 0.5rem;
        align-self: flex-start;
        width: fit-content;
      }
      .mainText {
        font-family: "Nunito", sans-serif;
        font-size: 2.7rem;
        margin-bottom: 0.5rem;
        line-height: 1.3;
        letter-spacing: 2px;
        font-weight: bold;
      }
    }

    .subText {
      margin-bottom: 2.5rem;
      font-weight: bold;
      text-align: center;
      color: #ff7233;
    }
    .number {
      display: flex;
      align-items: center;
      padding: 0.3rem 1rem;
      color: #4caf50;
      font-weight: bold;
      font-size: 30px;
      width: fit-content;
      margin: auto;
      a {
        color: #4caf50;
        text-decoration: none;
      }
      &:hover {
        color: #4caf50;
      }
      .phoneIcon {
        color: #4caf50;
        margin-right: 7px;
        position: relative;
        top: 3px;
      }
    }
    .weekText {
      font-weight: bold;
      text-align: center;
      color: #ff7233;
    }
  }
}
@media screen and (max-width: 3850px) {
  .heroSection {
    padding-bottom: 55rem;
  }
}
@media screen and (max-width: 2570px) {
  .heroSection {
    padding-bottom: 33rem;
  }
}
@media screen and (max-width: 2058px) {
  .heroSection {
    padding-bottom: 25rem;
  }
}
@media screen and (max-width: 1940px) {
    .heroSection {
      padding-bottom: 22rem;
    }
}

@media screen and (max-width: 1688px) {
  .heroSection {
    padding-bottom: 20rem;
  }
}

@media screen and (max-width: 1440px) {
  .heroSection {
    padding-bottom: 15rem;
  }
}
@media screen and (max-width: 1300px) {
  .heroSection {
    padding-bottom: 12rem;
    .top {
      .btns {
        p {
          // padding: 1rem;
          // align-self: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1190px) {
  .heroSection {
    .top {
      .topHead {
        .mainText {
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .heroSection {
    padding: 2rem 1rem;
    padding-top: 110px;
    background-size: 100% auto;
    padding-bottom: 10rem;
    .top {
      .topHead {
        .adivceText {
          width: fit-content;
          margin: auto;
          margin-bottom: 0.5rem;
        }
        .mainText {
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .heroSection {
    padding: 2rem 0.5rem;
    padding-top: 110px;
    background-size: 100% auto;
    padding-bottom: 7rem;
    .top {
      .btns {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
        grid-gap: 1rem;
      }
      .topHead {
        .adivceText {
          width: fit-content;
          margin: auto;
          margin-bottom: 1.5rem;
        }
        .mainText {
          font-size: 1.75rem;
          margin-bottom: 1.5rem;
          line-height: 1.1;
          letter-spacing: 1px;
          text-align: center;
        }
      }
      .subText {
      }
      .number {
      }
    }
  }
}
