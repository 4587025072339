.guide {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 5rem;
  min-height: 100vh;
  box-sizing: border-box;
  &.bLoans {
    background-color: #c9e7cb;
  }
  &.dFinance {
    background-color: #cce0ff;
  }
  &.sRepo {
    background-color: #ffdccc;
  }
  &.reSources {
    background-color: #e1d8f1;
  }
  .topText {
    text-align: justify;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5rem;
    .left {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
      p {
        cursor: pointer;
        color: black;
        border-bottom: 3px solid transparent;
        &.bLoans {
          color: #4caf50;
          &.selected,
          &:hover {
            border-bottom: 3px solid #4caf50;
          }
        }
        &.dFinance {
          color: #002d72;
          &.selected,
          &:hover {
            border-bottom: 3px solid #002d72;
          }
        }
        &.sRepo {
          color: #ff7233;
          &.selected,
          &:hover {
            border-bottom: 3px solid #ff7233;
          }
        }
        &.caseStudies {
          color: #673ab7;
          &.selected,
          &:hover {
            border-bottom: 3px solid #673ab7;
          }
        }
      }
    }
    .right {
      .rightHead {
        margin-bottom: 1rem;
      }
      .blogTitleList {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        a {
          width: fit-content;
          background-color: white;
          padding: 0.3rem;
          text-decoration: none;
          color: black;
          margin-bottom: 1rem;
          border-bottom: 3px solid transparent;
          width: 100%;
          &.bLoans {
            border-left: 3px solid #4ccb14;
            &:hover {
              background-color: #4ccb14;
              border-bottom: 3px solid #4ccb14;
              color: white;
            }
          }
          &.sRepo {
            border-left: 3px solid #ff7233;
            &:hover {
              background-color: #ff7233;
              border-bottom: 3px solid #ff7233;
              color: white;
            }
          }
          &.caseStudies {
            border-left: 3px solid #673ab7;
            &:hover {
              background-color: #673ab7;
              border-bottom: 3px solid #673ab7;
              color: white;
            }
          }
          &.dFinanace {
            border-left: 3px solid #002d72;
            &:hover {
              background-color: #002d72;
              border-bottom: 3px solid #002d72;
              color: white;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .guide {
    width: 100%;
    padding: 2rem 1rem;
    padding-top: 100px;
    .content {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;
      .left {
        gap: 1rem;
      }
      .right {
        .blogTitleList {
          a {
            width: 80%;
          }
        }
      }
    }
  }
}
