.featuresContainer {
  padding: 3rem 2rem;
  .header {
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-family: "Nunito", sans-serif;
  }
  .features {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    .feature {
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      line-height: 1.8rem;
      padding: 1.5rem 1rem;
      color: white;
      &.bLoans {
        background-color: #4caf50;
      }
      border-radius: 10px;
      &.dFinance {
        background-color: #002d72;
        // border: 1px solid #002d72;
        // &:hover {
        //   background-color: white;
        //   color: #002d72;
        // }
      }
      &.sRepo {
        background-color: #ff7233;
        // border: 1px solid #ff7233;
        // &:hover {
        //   background-color: white;
        //   color: #ff7233;
        // }
      }
      &.reSources {
        background-color: #673ab7;
        // border: 1px solid #673ab7;
        // &:hover {
        //   background-color: white;
        //   color: #673ab7;
        // }
      }
      .head {
        font-weight: bold;
        font-size: 1.3rem;
      }
      .content {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        // flex: 1;
        .gif {
          height: 100px;
          width: 100px;
        }
        .subhead {
          // display: flex;
          // flex-direction: column;
          // padding-bottom: 0;
          // margin-top: 1rem;
          li {
            list-style: none;
            font-size: 0.9rem;
            padding-top: 0.3rem;
          }
        }
      }

      .link {
        text-decoration: none;
        background-color: white;
        font-weight: bold;
        padding: 0.4rem 1.5rem;
        border-radius: 20px;
        width: fit-content;
        font-size: 0.9rem;

        &.bLoans {
          color: #4caf50;
          // border: 1px solid #4caf50;
          // &:hover {
          //   background-color: white;
          //   color: #4caf50;
          // }
        }
        &.dFinance {
          color: #002d72;
          // border: 1px solid #002d72;
          // &:hover {
          //   background-color: white;
          //   color: #002d72;
          // }
        }
        &.sRepo {
          color: #ff7233;
          // border: 1px solid #ff7233;
          // &:hover {
          //   background-color: white;
          //   color: #ff7233;
          // }
        }
        &.reSources {
          color: #673ab7;
          // border: 1px solid #673ab7;
          // &:hover {
          //   background-color: white;
          //   color: #673ab7;
          // }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .featuresContainer {
    padding: 3rem 2rem;
    .header {
      font-weight: bold;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
    .features {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      .feature {
        padding: 2rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .head {
          font-weight: bold;
        }
        .subhead {
          text-align: justify;
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          font-weight: bold;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .featuresContainer {
    padding: 0;
    margin-top: 1.5rem;
    .header {
      font-weight: bolder;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 1.8rem;
      font-family: "Nunito", sans-serif;
      color: white;
      padding: 3rem 0 8rem 0;
      margin-bottom: -6rem;
      background-color: #ff7233;
    }
    .features {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      .feature {
        padding: 1rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        gap: 0rem;
        .head {
          font-weight: bold;
          align-items: center;
          margin: 0.5rem 0 2rem;
        }
        .content {
          .gif {
            height: 110px;
            width: 110px;
            background-image: none;
            position: relative;
            // top: -30px;
          }
        }

        .subhead {
          text-align: justify;
          margin: 0 0 0rem 1rem;
          line-height: 2rem;
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          font-weight: bold;
          margin: 2rem 0 0.5rem 1rem;
        }
      }
    }
  }
}
