.callAdviser {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  &.bLoans {
    background-color: #b7dfb9;
  }
  &.dFinance {
    background-color: #d2e4ff;
  }
  &.reSources {
    background-color: #e1d8f1;
  }
  &.sRepo {
    background-color: #faddce;
  }

  .top {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    align-items: center;
    justify-items: center;
    grid-gap: 3rem;
    > * {
      // border: 1px solid black;
    }
    .left {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .center {
    }
    .right {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;
      .rightTopText {
        margin-bottom: 0.5rem;
      }
      .rightBottomText {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .number {
        color: #4caf50;
        border: 1px solid #4caf50;
        padding: 0.2rem 0.5rem;
        // margin-bottom: 0.5rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        text-decoration: none;
        .phoneIcon {
          position: relative;
          top: 0px;
          margin-right: 5px;
        }
        &.bLoans {
          border: 1px solid #4caf50;
          color: #4caf50;
          &:hover {
            color: #4caf50;
            // background-color: white;
          }
        }
        &.dFinance {
          border: 1px solid #002d72;
          color: #002d72;
          &:hover {
            color: #002d72;
            // background-color: white;
          }
        }
        &.sRepo {
          color: #ff7233;
          border: 1px solid #ff7233;
          &:hover {
            color: #ff7233;
            // background-color: white;
          }
        }
        &.reSources {
          color: #673ab7;
          border: 1px solid #673ab7;
          &:hover {
            color: #673ab7;
            // background-color: white;
          }
        }
      }
    }
  }
  .continueLink {
    text-decoration: none;
    color: white;
    background-color: #4caf50;
    padding: 0.5rem 1.5rem;
    width: fit-content;
    display: block;
    margin: auto;
    &.bLoans {
      background-color: #4caf50;
      border: 1px solid #4caf50;
      &:hover {
        background-color: white;
        color: #4caf50;
      }
    }
    &.dFinance {
      background-color: #002d72;
      border: 1px solid #002d72;
      &:hover {
        background-color: white;
        color: #002d72;
      }
    }
    &.sRepo {
      background-color: #ff7233;
      border: 1px solid #ff7233;
      &:hover {
        background-color: white;
        color: #ff7233;
      }
    }
    &.reSources {
      background-color: #673ab7;
      border: 1px solid #673ab7;
      &:hover {
        background-color: white;
        color: #673ab7;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .callAdviser {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    &.bLoans {
      background-color: #b7dfb9;
    }
    &.dFinance {
      background-color: #d2e4ff;
    }
    .top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-items: center;
      grid-gap: 1rem;

      .left {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
      }
      .center {
      }
      .right {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
        .rightTopText {
          margin-bottom: 0.5rem;
        }
        .rightBottomText {
          font-weight: bold;
          font-size: 0.9rem;
        }
        .number {
          color: #4caf50;
          border: 1px solid #4caf50;
          padding: 0.2rem 0.5rem;
          // margin-bottom: 0.5rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          img {
          }
          &.bLoans {
            color: #4caf50;
            border: 1px solid #4caf50;
          }
          &.dFinance {
            color: #002d72;
            border: 1px solid #002d72;
          }
        }
      }
    }
    .continueLink {
      text-decoration: none;
      color: white;
      background-color: #4caf50;
      padding: 0.5rem 1.5rem;
      width: fit-content;
      display: block;
      margin: auto;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
    }
  }
}
