.submittedForms {
  padding: 0 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .header {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .formItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #3a57e8;
    background-color: #f1f3ff;
    padding: 2rem;
    border-radius: 10px;
    .left {
      display: flex;
      flex-direction: column;
    }
    .right {
      display: flex;
      gap: 1rem;
      span {
        border: 1px solid black;
        padding: 0.1rem 0.3rem;
        height: fit-content;
        cursor: pointer;
        .icon {
          font-size: 1.2rem;
        }
        &:nth-last-child(1) {
          border: none;
          color: black;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .submittedForms {
    padding: 1rem;
    .formItem {
      padding: 1rem;
    }
  }
}
