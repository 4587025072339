.footer {
  background-color: #ffdccc;
  &.bLoans {
    background-color: #c9e7cb;
  }
  &.dFinance {
    background-color: #cce0ff;
  }
  &.sRepo {
    background-color: #ffdccc;
  }
  &.reSources {
    background-color: #e1d8f1;
  }

  .footerContent {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    .top {
      display: inline-grid;
      grid-template-columns: 2fr 2fr 3fr;
      grid-gap: 2rem;
      justify-items: center;
      .left {
        display: flex;

        flex-direction: column;
        gap: 0.5rem;
        line-height: 1.25;
        // text-align: justify;
        .head {
          font-weight: bold;
        }
      }
      .right {
        width: 100%;

        .locationImg {
          height: 200px;
          width: 200px;
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        .centerTop {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .addressHead {
            font-weight: bold;
          }
        }
        .centerBottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          a {
            text-decoration: none;
            color: black;
          }
          .icon {
            position: relative;
            top: 6px;
            font-size: 1.75rem;
            // color: #ff7233;
            &.bLoans {
              color: #4caf50;
            }
            &.dFinance {
              color: #002d72;
            }
            &.sRepo {
              color: #ff7233;
            }
            &.reSources {
              color: #673ab7;
            }
            &.home {
              color: #ff7233;
            }
          }
          .socialIcons {
            display: flex;
            gap: 1.5rem;
            .socialIcon {
              font-size: 1.75rem;
              color: #ff7233;
              cursor: pointer;
              &.bLoans {
                color: #4caf50;
              }
              &.dFinance {
                color: #002d72;
              }
              &.sRepo {
                color: #ff7233;
              }
              &.reSources {
                color: #673ab7;
              }
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      .links {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        a:hover {
          color: #4caf50;
        }
        &.bLoans {
          a:hover {
            color: #4caf50;
          }
        }
        &.dFinance {
          a:hover {
            color: #002d72;
          }
        }
        &.sRepo {
          a:hover {
            color: #ff7233;
          }
        }
        &.reSources {
          a:hover {
            color: #673ab7;
          }
        }
        a {
          text-decoration: none;
          color: black;
          font-size: 0.9rem;
        }
      }
      .copy {
        font-size: 0.9rem;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .footer {
    .footerContent {
      width: 90%;
      padding: 1rem;
      .top {
        display: inline-grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
        justify-items: flex-start;
        .left {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          line-height: 1.25;
          .head {
            font-weight: bold;
          }
        }
        .right {
          .locationImg {
            width: 90vw;
            height: 200px;
          }
        }
        .center {
          display: flex;
          flex-direction: column;

          gap: 1rem;
          .centerTop {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .addressHead {
              font-weight: bold;
            }
          }
          .centerBottom {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .icon {
              position: relative;
              top: 6px;
              font-size: 25px;
              color: #4caf50;
            }
          }
        }
      }
      .bottom {
        gap: 0.5rem;
        .links {
          gap: 0.5rem;
        }
      }
    }
  }
}
