.bredCrum {
  &.bLoans {
    background-color: #b7dfb9;
  }
  &.dFinance {
    background-color: #c2daff;
  }
  &.sRepo {
    background-color: #ffdccc;
  }
  &.reSources {
    background-color: #e1d8f1;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 90%;
  // margin: auto;
  // margin-top: 1rem;
  padding: 0.5rem 5rem;
  gap: 1rem;
  a {
    padding: 0.5rem;
    color: white;
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    text-decoration: none;
  }
  .arrowIcon {
    &.bLoans {
      color: #4caf50;
    }
    &.dFinance {
      color: #002d72;
    }
    &.sRepo {
      color: #ff7233;
    }
    &.reSources {
      color: #673ab7;
    }
  }
}
@media screen and (max-width: 900px) {
  .bredCrum {
    &.bLoans {
      background-color: #b7dfb9;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    &.reSources {
      background-color: #e1d8f1;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem 5rem;
    gap: 1rem;
    // display: none;
    a {
      padding: 0.5rem;
      color: white;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      &.reSources {
        background-color: #673ab7;
      }
      text-decoration: none;
    }
    .arrowIcon {
      &.bLoans {
        color: #4caf50;
      }
      &.dFinance {
        color: #002d72;
      }
      &.sRepo {
        color: #ff7233;
      }
      &.reSources {
        color: #673ab7;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .bredCrum {
    &.bLoans {
      background-color: #b7dfb9;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    &.reSources {
      background-color: #e1d8f1;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem 5rem;
    gap: 1rem;
    display: none;
    a {
      padding: 0.5rem;
      color: white;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      &.reSources {
        background-color: #673ab7;
      }
      text-decoration: none;
    }
    .arrowIcon {
      &.bLoans {
        color: #4caf50;
      }
      &.dFinance {
        color: #002d72;
      }
      &.sRepo {
        color: #ff7233;
      }
      &.reSources {
        color: #673ab7;
      }
    }
  }
}
