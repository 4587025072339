.editForm {
  width: 90%;
  margin: 2rem auto;
  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    p {
      font-size: 1.2rem;
    }
    div {
      display: flex;
      gap: 1rem;

      span {
        font-size: 20px;
        cursor: pointer;
        color: #576baa;
        border: 1px solid #8d9bca;
        padding: 2px 15px;
        //   &.deleteModal {
        //     border: none;
        //     padding: 0px;
        //     &:hover {
        //       background-color: none;
        //       color: none;
        //     }
        //   }
        &:hover {
          background-color: #576baa;
          color: white;
        }
      }
    }
  }
  .bottom {
    overflow: auto;
    .tableHead {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1rem;
      .tableCaption {
        text-align: left;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
      td,
      th {
        border: 1px solid #cdd1dd;
        text-align: left;
        padding: 8px;
      }
      tr {
        td:nth-child(1) {
          width: 65%;
        }
        td:nth-child(2) {
          background-color: #3a57e8;
          color: white;
          width: 35%;
          input,
          select,
          textarea {
            box-sizing: border-box;
            outline: none;
            width: 100%;
            padding: 0.5rem;
            border-radius: 5px;
            border: 1px solid #ddd;
          }
          textarea {
            height: 100px;
          }
        }
      }
      &:nth-of-type(1) {
        tr {
          td:nth-child(2) {
            background-color: #ff7233;
          }
        }
      }
      &:nth-of-type(2) {
        tr {
          td:nth-child(2) {
            background-color: #ff7233;
          }
        }
      }
      &:nth-of-type(3) {
        tr {
          td:nth-child(2) {
            background-color: #673ab7;
          }
        }
      }
      &:nth-of-type(4) {
        tr {
          td:nth-child(2) {
            background-color: #4caf50;
          }
        }
      }
      &:nth-of-type(5) {
        tr {
          td:nth-child(2) {
            background-color: #002d72;
          }
        }
      }
      &:nth-of-type(6) {
        tr {
          td:nth-child(2) {
            background-color: #f8ab17;
          }
        }
      }
    }
  }
  .loadingContainer {
    .loadingImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .editForm {
    padding-top: 100px;
    width: 95%;
  }
}
@media screen and (max-width: 400px) {
  .editForm {
    .top {
      flex-direction: column;
      div {
        align-self: flex-end;
      }
    }
    .bottom {
      table {
        tr {
          td:nth-child(1) {
            width: 60%;
          }
          td:nth-child(2) {
            width: 40%;
          }
        }
      }
    }
  }
}
