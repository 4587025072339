.bridgningHeroSetion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 70%;
  margin: auto;
  padding: 2rem 0;
  .header {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    font-family: "Nunito", sans-serif;
  }
  .mainContent {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    grid-gap: 2rem;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      .leftHead {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bold;
      }
      .leftNumber {
        color: white;
        padding: 0.75rem 1.5rem;
        width: fit-content;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        &.bLoans {
          border: 1px solid #4caf50;
          background-color: #4caf50;
          &:hover {
            color: #4caf50;
            background-color: white;
          }
        }
        &.dFinance {
          border: 1px solid #002d72;
          background-color: #002d72;
          &:hover {
            color: #002d72;
            background-color: white;
          }
        }
        &.sRepo {
          background-color: #ff7233;
          border: 1px solid #ff7233;
          &:hover {
            color: #ff7233;
            background-color: white;
          }
        }
        &.reSources {
          background-color: #673ab7;
          border: 1px solid #673ab7;
          &:hover {
            color: #673ab7;
            background-color: white;
          }
        }
        .phoneIcon {
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
      .leftText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      .rightHead {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: bold;
      }
      .rightNumber {
        color: white;
        padding: 0.75rem 1.5rem;
        width: fit-content;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;

        &.bLoans {
          border: 1px solid #4caf50;
          background-color: #4caf50;

          &:hover {
            color: #4caf50;
            background-color: white;
          }
        }
        &.dFinance {
          background-color: #002d72;
          border: 1px solid #002d72;

          &:hover {
            color: #002d72;
            background-color: white;
          }
        }
        &.sRepo {
          border: 1px solid #ff7233;
          background-color: #ff7233;

          &:hover {
            color: #ff7233;
            background-color: white;
          }
        }
        &.reSources {
          background-color: #673ab7;
          border: 1px solid #673ab7;
          &:hover {
            color: #673ab7;
            background-color: white;
          }
        }
      }
      .rightText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .borderTop {
        width: 3px;
        height: 60px;
        background-color: black;
      }
      .text {
      }
      .borderBottom {
        width: 3px;
        height: 60px;
        background-color: black;
      }
    }
  }
  .mainborderBottom {
    height: 8px;
    width: 100%;
    // background-color: #4caf50;
    display: flex;
    justify-content: center;
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    .traingleIcon {
      font-size: 3rem;
      text-align: center;
      position: relative;
      top: -7px;
      &.bLoans {
        color: #4caf50;
      }
      &.dFinance {
        color: #002d72;
      }
      &.sRepo {
        color: #ff7233;
      }
      &.reSources {
        color: #673ab7;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .bridgningHeroSetion {
    padding-top: 100px;
  }
}
@media screen and (max-width: 500px) {
  .bridgningHeroSetion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 70%;
    margin: auto;
    padding: 2rem 0;
    padding-top: 120px;

    .header {
      font-size: 2rem;
      font-weight: bold;
    }
    .mainContent {
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      grid-gap: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .leftHead {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          font-weight: bold;
        }
        .leftNumber {
          color: white;
          padding: 0.75rem 1.5rem;
          // background-color: #4caf50;
          width: fit-content;
          border-radius: 4px;
          font-weight: bold;
          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
        }
        .leftText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .rightHead {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          font-weight: bold;
        }
        .rightNumber {
          color: white;
          padding: 0.75rem 1.5rem;
          // background-color: #4caf50;
          width: fit-content;
          border-radius: 4px;
          font-weight: bold;

          &.bLoans {
            background-color: #4caf50;
          }
          &.dFinance {
            background-color: #002d72;
          }
        }
        .rightText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
      }
      .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        //   border: 1px solid black;
        .borderTop {
          width: 100px;
          height: 3px;
          background-color: black;
          // display: none;
        }
        .text {
        }
        .borderBottom {
          // display: none;
          width: 100px;
          height: 3px;
          background-color: black;
        }
      }
    }
    .mainborderBottom {
      height: 8px;
      width: 100%;
      // background-color: #4caf50;
      display: flex;
      justify-content: center;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      .traingleIcon {
        font-size: 3rem;
        text-align: center;
        // color: #4caf50;
        position: relative;
        top: -7px;
        &.bLoans {
          color: #4caf50;
        }
        &.dFinance {
          color: #002d72;
        }
      }
    }
  }
}
