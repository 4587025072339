.subHeroSection {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff7233;
  color: white;
  .topText {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #002d72;
  }
  .subHeadText {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    img {
      height: 1.5rem;
      width: 1.5rem;
    }
    padding: 0.25rem 1rem;
    // border: 1px solid #4caf50;
    // color: #4caf50;
    a {
      text-decoration: none;
      color: #002d72;
      font-size: 2rem;
      .phoneIcon {
        position: relative;
        top: 3px;
        margin-right: 6px;
      }
    }
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .applyOnline {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 2rem;
    cursor: pointer;
    // border-bottom: 3px solid #002d72;
    padding: 0.3rem 1rem;
  }
  .btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 40%;
    grid-gap: 2rem;
    margin: auto;
    a {
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      border: 1px solid #002d72;
      color: #ff7233;
      font-size: 1.2rem;
    }
    .loans {
      color: white;
      background-color: #002d72;
      border: 1px solid #002d72;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: #002d72;

        background-color: white;
      }
    }
    .finance {
      color: white;
      background-color: #002d72;
      border: 1px solid #002d72;
      &:hover {
        color: #002d72;

        background-color: white;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .subHeroSection {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .topText {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .subHeadText {
      margin-bottom: 2rem;
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
      padding: 0.25rem 1rem;
      // border: 1px solid #4caf50;
      // color: #4caf50;
      margin-bottom: 2rem;
      font-weight: bold;
      font-size: 1.25rem;
    }
    .applyOnline {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    .btns {
      // display: flex;
      // justify-content: center;
      // gap: 2rem;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      // a {
      //   text-decoration: none;
      //   padding: 0.5rem 1rem;
      //   border: 1px solid #4caf50;
      // }
      // .loans {
      //   background-color: #4caf50;
      //   color: white;
      //   &:hover {
      //     color: #4caf50;
      //     background-color: transparent;
      //   }
      // }
      // .finance {
      //   color: #4caf50;
      //   &:hover {
      //     color: white;
      //     background-color: #4caf50;
      //   }
      // }
    }
  }
}
@media screen and (max-width: 500px) {
  .subHeroSection {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    .topText {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .subHeadText {
      margin-bottom: 2rem;
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
      padding: 0.25rem 1rem;
      // border: 1px solid #4caf50;
      // color: #4caf50;
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: 1.25rem;
    }
    .applyOnline {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    .btns {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      a {
        text-decoration: none;
        padding: 0.5rem 0.75rem;
        // border: 1px solid #4caf50;
      }
      // .loans {
      //   // background-color: #4caf50;
      //   color: white;
      //   &:hover {
      //     color: #4caf50;
      //     background-color: transparent;
      //   }
      // }
      // .finance {
      //   color: #4caf50;
      //   &:hover {
      //     color: white;
      //     background-color: #4caf50;
      //   }
      // }
    }
  }
}
