.acceptedDocuments {
  .header {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .section {
    margin-bottom: 2rem;
    .sectionHead {
      color: white;
      background-color: #3a57e8;
      padding: 0.6rem 0;
      font-weight: bold;
      width: 70%;
      margin: auto;
      border-radius: 10px;
      margin-bottom: 2rem;
      text-align: center;
    }
    .sectionItems {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      .leftItem {
        background-color: #f8f8ff;
        border: 1px solid #3a57e8;
        border-style: dashed;
        padding: 2rem;
        border-radius: 8px;
        .leftItemHead {
          color: white;
          background-color: #3a57e8;
          padding: 0.5rem 2rem;
          width: fit-content;
          margin: auto;
          border-radius: 10px;
          text-align: center;
          margin-bottom: 1rem;
        }
        ul {
          li {
            margin-bottom: 0.5rem;
            &.sublist {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 2rem;
              padding: 1rem;
              ul {
                &:nth-child(1) {
                  li {
                    &:nth-child(1) {
                      position: relative;
                      left: -1rem;
                      list-style: none;
                      background-color: #b0e8b2;
                      padding: 0.3rem 1rem;
                      border-radius: 8px;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
                &:nth-child(2) {
                  li {
                    &:nth-child(1) {
                      position: relative;
                      left: -1rem;
                      list-style: none;
                      background-color: #e8b0b0;
                      padding: 0.3rem 1rem;
                      border-radius: 8px;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .rightItem {
        background-color: #f8f8ff;
        border: 1px solid #3a57e8;
        border-style: dashed;
        padding: 2rem;
        border-radius: 8px;

        .rightItemHead {
          color: white;
          background-color: #3a57e8;
          padding: 0.5rem 2rem;
          width: fit-content;
          margin: auto;

          border-radius: 10px;
          text-align: center;
          margin-bottom: 1rem;
        }
        ul {
          li {
            margin-bottom: 0.5rem;

            &.sublist {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 2rem;
              padding: 1rem;
              ul {
                &:nth-child(1) {
                  li {
                    &:nth-child(1) {
                      position: relative;
                      left: -1rem;
                      list-style: none;
                      background-color: #b0e8b2;
                      padding: 0.3rem 1rem;
                      border-radius: 8px;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
                &:nth-child(2) {
                  li {
                    &:nth-child(1) {
                      position: relative;
                      left: -1rem;
                      list-style: none;
                      background-color: #e8b0b0;
                      padding: 0.3rem 1rem;
                      border-radius: 8px;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.center {
      .sectionItems {
        display: flex;
        justify-content: center;
        .leftItem {
          padding: 2rem 3rem;
        }
      }
    }
  }
}
@media screen and (max-width: 950px) {
  .acceptedDocuments {
    .section {
      .sectionItems {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .acceptedDocuments {
    .section {
      .sectionItems {
        .leftItem {
          padding: 1rem 2rem;
        }
        .rightItem {
          padding: 1rem 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .acceptedDocuments {
    .section {
      .sectionItems {
        .leftItem {
          ul {
            li {
              &.sublist {
                grid-template-columns: repeat(1, 1fr);
                ul {
                  li {
                    &:nth-child(1) {
                      width: fit-content;
                    }
                  }
                }
              }
            }
          }
        }
        .rightItem {
          padding: 1rem 2rem;
          ul {
            li {
              &.sublist {
                grid-template-columns: repeat(1, 1fr);
                ul {
                  li {
                    &:nth-child(1) {
                      width: fit-content;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
