.faqCard {
  overflow: hidden;
  margin-top: 1.25rem;
  border-bottom: solid 1px #e5e7eb;
}
.faqCard > * + * {
  margin-top: 0.75rem;
}
.faqCard h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: black;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 6px;
  gap: 1rem;
  &.bLoans {
    background-color: #b7dfb9;
  }
  &.dFinance {
    background-color: #d2e4ff;
  }
  &.sRepo {
    background-color: #ffdccc;
  }
  &.reBridging {
    background-color: #e1d8f1;
  }
}
.faqCard h4 span .icon {
  position: relative;
  top: 5px;
}
.faqCard .answerContainer {
  transition-duration: 300ms;
}
.faqCard .answerContainer div p {
  color: black;
}
