.boxes {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  padding: 2rem 0;
  div {
    width: 450px;
    height: 300px;
    &.bLoans {
      border: 1px solid #4caf50;
    }
    &.dFinance {
      border: 1px solid #002d72;
    }
    &.sRepo {
      border: 1px solid #ff7233;
    }
    &.reSources {
      border: 1px solid #673ab7;
    }
  }
  p {
    font-weight: bold;
    padding: 0.5rem 3rem;
    &.bLoans {
      background-color: #b7dfb9;
    }
    &.dFinance {
      background-color: #d2e4ff;
    }
    &.sRepo {
      background-color: #ff7233;
      color: white;
    }
    &.reSources {
      background-color: #673ab7;
      color: white;
    }
    text-align: center;
  }
  .video {
    .iframe {
      height: 265px;
      width: 100%;
    }
  }
  .calculator {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 900px) {
  .boxes {
    div {
      width: 350px;
      height: 275px;
    }
    .video {
      .iframe {
        height: 222px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .boxes {
    div {
      width: 300px;
      height: 250px;
    }
    .video {
      .iframe {
        height: 187px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .boxes {
    div {
      width: 250px;
      height: 225px;
    }
    .video {
      .iframe {
        height: 172px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .boxes {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    div {
      width: 350px;
      height: 250px;
    }
    .video {
      .iframe {
        height: 197px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .boxes {
    div {
      width: 275px;
      height: 250px;
    }
    .video {
      .iframe {
        height: 197px;
        width: 100%;
      }
    }
  }
}
