.matchingMortgages {
  width: 60%;
  margin: 2rem auto;
  .header {
    &.bLoans {
      background-color: #4caf50;
    }
    &.dFinance {
      background-color: #002d72;
    }
    &.sRepo {
      background-color: #ff7233;
    }
    &.reSources {
      background-color: #673ab7;
    }
    color: white;
    padding: 0.6rem 1rem;
    font-size: 1.1rem;
  }
  .infoBox {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.bLoans {
      background-color: #c9e7cb;
    }
    &.dFinance {
      background-color: #c2daff;
    }
    &.sRepo {
      background-color: #ffdccc;
    }
    &.reSources {
      background-color: #e1d8f1;
    }
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    strong{
      display: block;
      margin-bottom: 0.5rem;
    }

    .qandA {
      .question {
        font-weight: bold;
      }
      .answer {
      }
    }
  }
}
@media screen and (max-width:900px) {
  .matchingMortgages {
    width: 85%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .infoBox {
      padding: 2rem 4rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #c2daff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      strong{
        display: block;
        margin-bottom: 0.5rem;
      }
  
      .qandA {
        .question {
          font-weight: bold;
        }
        .answer {
        }
      }
    }
  }
}
@media screen and (max-width:500px) {
  .matchingMortgages {
    width: 95%;
    margin: 2rem auto;
    .header {
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      &.sRepo {
        background-color: #ff7233;
      }
      color: white;
      padding: 0.6rem 1rem;
      font-size: 1.1rem;
    }
    .infoBox {
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #c2daff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      strong{
        display: block;
        margin-bottom: 0.5rem;
      }
  
      .qandA {
        .question {
          font-weight: bold;
        }
        .answer {
        }
      }
    }
  }
}