.progress_bar_container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.progress_bar {
  height: 5px;
  border-radius: 7px;

  &.loan {
    background: #ff7232;
  }

  &.security {
    background: #673ab7;
  }

  &.company {
    background: #4caf50;
  }

  &.director {
    background: #042c74;
  }

  &.additionalInfo {
    background: #f8ab17;
  }
}
