.calculatorPage {
  width: 85%;
  margin: 2rem auto;
  .top {
    margin-bottom: 2rem;
    .header {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
      text-align: center;
    }
    .links {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      a {
        text-decoration: none;
        text-align: center;
        color: black;
        background-color: #f1f3ff;
        padding: 1.2rem 2rem;
        border-radius: 10px;
        &.active {
          background-color: #3a57e8;
          color: white;
        }
      }
    }
  }
  .bottom {
    .bridgingLoanCalculator {
      .top {
        .header {
          text-align: center;
          font-size: 1.3rem;
          font-weight: bold;
        }
        .inputs {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 2rem;

          .inputBlock {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            label {
              background-color: #f1f3ff;
              padding: 0.7rem 1rem;
              border-radius: 5px;
              font-weight: bold;
            }
            input {
              height: 35px;
              border-radius: 10px;
              border: 1px solid #f1f3ff;
              padding-left: 1rem;
              outline: none;
              background-color: #f1f3ff;
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
              }
            }
          }
        }
        .btns {
          display: flex;
          justify-content: flex-end;
          gap: 2rem;
          margin-top: 2rem;
          button {
            padding: 0.6rem 2rem;
            border-radius: 5px;
            cursor: pointer;
            background-color: #3a57e8;
            color: white;
            border: none;
            &:nth-child(2) {
              background-color: #f1f3ff;
              color: black;
              border: 1px solid #3a57e8;
            }
            &.disabled:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      .bottom {
        // display: flex;
        // justify-content: space-between;
        // gap: 2rem;
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-gap: 2rem;
        .bottomLeft,
        .bottomRight {
          .bottomHead {
            color: white;
            background-color: #3a57e8;
            padding: 0.5rem;
            border-radius: 10px;
            margin-bottom: 1.5rem;
          }
          .result {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .resultItem {
              display: grid;
              grid-template-columns: 5fr 2fr;
              grid-gap: 2rem;
              .label {
                font-weight: bold;
              }
              .value {
                padding: 0.5rem 2rem;
                background-color: #f1f3ff;
                text-align: center;
              }
            }
          }
        }
        .bottomCenter {
          border: 1px solid #f1f3ff;
          padding: 2rem 1rem;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          justify-content: flex-start;
          align-items: center;
          font-size: 1.1rem;
          text-align: center;
          color: white;
          background-color: #3a57e8;
          border-radius: 10px;
          height: fit-content;
          p {
            line-height: 1.2;
          }
          .number {
            font-size: 2rem;
            font-weight: bold;
          }
          button {
            border: 1px solid #3a57e8;
            padding: 0.4rem 0.5rem;
            background-color: #4caf50;
            color: white;
            border-radius: 10px;
            width: 90%;
            font-size: 1.5rem;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .loadingContainer {
        .loadingImg {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}




@media screen and (max-width: 1000px) {
  .calculatorPage {
    padding-top: 100px;
    width: 95%;
  }
}
@media screen and (max-width: 850px) {
  .calculatorPage {
    .bottom {
      .bridgingLoanCalculator {
        .top {
          .inputs {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .calculatorPage {
    .bottom {
      .bridgingLoanCalculator {
        .bottom {
          flex-direction: column;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .calculatorPage {
    .top {
      .links {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
      }
    }
    .bottom {
      .bridgingLoanCalculator {
        .top {
          .inputs {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}

