.stepsContainer {
  padding: 1.5rem 0 2rem 0;
  background-color: white;
  .header {
    width: 30%;
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 2rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff7233;
    color: white;
    width: 100%;
    padding: 3rem 0 10rem 0;
    box-sizing: border-box;
    .headerText {
      width: 65%;
      margin: auto;
      font-family: "Nunito", sans-serif;
      font-weight: 900;
    }
  }
  .steps {
    margin-top: -10rem;
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
    .stepItem {
      background-color: white;
      padding: 2rem 1rem 2rem 1rem;
      padding-top: 0;
      border-radius: 40px;
      // border-radius: 10px;

      background: #fafafa;
      backdrop-filter: blur(4px);
      box-shadow: 25px 25px 75px rgba(0,0,0,0.15), 10px 10px 70px rgba(0, 0, 0, 0.15),inset -5px -5px 15px rgba(0, 0, 0, 0.15), 
      inset 5px 5px 15px rgba(0, 0, 0, 0.05);    
    
      // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      .heading {
        font-weight: bold;
        font-size: 1.2rem;
        text-decoration: none;
        &.bLoans {
          color: #4caf50;
          // border: 1px solid #4caf50;
          // &:hover {
          //   background-color: white;
          //   color: #4caf50;
          // }
        }
        &.dFinance {
          color: #002d72;
          // border: 1px solid #002d72;
          // &:hover {
          //   background-color: white;
          //   color: #002d72;
          // }
        }
        &.sRepo {
          color: #ff7233;
          // border: 1px solid #ff7233;
          // &:hover {
          //   background-color: white;
          //   color: #ff7233;
          // }
        }
        &.reSources {
          color: #673ab7;
          // border: 1px solid #673ab7;
          // &:hover {
          //   background-color: white;
          //   color: #673ab7;
          // }
        }
      }
      .subhead {
        text-align: justify; // need to be checked @saurabh
        padding: 0.5rem;
      }
      .link {
        text-decoration: none;
        color: #4caf50;
        font-weight: bold;
        &.bLoans {
          color: #4caf50;
          // border: 1px solid #4caf50;
          // &:hover {
          //   background-color: white;
          //   color: #4caf50;
          // }
        }
        &.dFinance {
          color: #002d72;
          // border: 1px solid #002d72;
          // &:hover {
          //   background-color: white;
          //   color: #002d72;
          // }
        }
        &.sRepo {
          color: #ff7233;
          // border: 1px solid #ff7233;
          // &:hover {
          //   background-color: white;
          //   color: #ff7233;
          // }
        }
        &.reSources {
          color: #673ab7;
          // border: 1px solid #673ab7;
          // &:hover {
          //   background-color: white;
          //   color: #673ab7;
          // }
        }
      }
      .img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .stepsContainer {
    padding: 1.5rem 0rem 2rem 0;
    .header {
      width: 30%;
      margin: auto;
      text-align: center;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      line-height: 1.2;
      letter-spacing: 1px;
      background-color: #ff7233;
      color: white;
      width: 100%;
      padding: 3rem 0 10rem 0;
      box-sizing: border-box;
      .headerText {
        width: 80%;
        margin: auto;
      }
    }
    .steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      .stepItem {
        padding: 1rem;
        // border-radius: 10px;

        background: #fafafa;
        backdrop-filter: blur(4px);
        box-shadow: 25px 25px 75px rgba(0,0,0,0.15), 10px 10px 70px rgba(0, 0, 0, 0.15),inset -5px -5px 15px rgba(0, 0, 0, 0.15), 
        inset 5px 5px 15px rgba(0, 0, 0, 0.05);

        // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .heading {
          font-weight: bold;
        }
        .subhead {
          text-align: justify;
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          font-weight: bold;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .stepsContainer {
    padding: 0.5rem 0rem;
    .header {
      width: 30%;
      margin: auto;
      text-align: center;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      line-height: 1.2;
      letter-spacing: 1px;
      background-color: #ff7233;
      color: white;
      width: 100%;
      padding: 3rem 0 10rem 0;
      box-sizing: border-box;
      .headerText {
        width: 80%;
        margin: auto;
      }
    }
    .steps {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      .stepItem {
        padding: 1rem 1rem 3rem 1rem;
        // border-radius: 10px;

        background: #fafafa;
        backdrop-filter: blur(4px);
        box-shadow: 25px 25px 75px rgba(0,0,0,0.15), 10px 10px 70px rgba(0, 0, 0, 0.15),inset -5px -5px 15px rgba(0, 0, 0, 0.15), 
        inset 5px 5px 15px #0000000d;

        // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .heading {
          font-weight: bold;
        }
        .subhead {
          text-align: left;
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          font-weight: bold;
        }
        .img {
          width: 125px;
          height: 125px;
        }
      }
    }
  }
}
