.dashboard {
  padding: 2rem;
  background-color: #fffbf9;
  min-height: 100vh;
  .header {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  .top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
    .card {
      //   border: 1px solid rgb(226, 206, 206);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      //   border-radius: 10px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      cursor: pointer;
      padding: 1rem;
      &:hover {
        background-color: #ffdccc;
      }
      p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .number {
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
  }
  .bottom {
    .formSubmissionUI {
      //   padding: 2rem;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        p {
          font-size: 1.2rem;
          font-weight: 500;
        }
        div {
          display: flex;
          align-items: center;
          // gap: 20px;
          label {
            font-weight: 500;
            margin-right: 10px;
            font-size: 0.9rem;
          }
          input {
            height: 30px;
            cursor: pointer;
          }
          .refreshIcon {
            margin-left: 20px;
            height: 30px;
            font-size: 20px;
            padding: 3px 10px;
            cursor: pointer;
            color: #ff7233;
            border: 1px solid #ffdccc;
            padding: 2px 15px;
            &:hover {
              background-color: #ff7233;
              color: white;
            }
          }
        }
      }
      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        .formCard {
          border: 1px solid rgb(230, 219, 219);
          background-color: white;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 2px 2px rgba(0, 0, 0, 0.23);
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          .left {
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            span {
              cursor: pointer;
              font-size: 20px;
              color: #ff7233;
              border: 1px solid #ffdccc;
              padding: 2px 15px;
              &.deleteModal {
                border: none;
                padding: 0px;
                &:hover {
                  background-color: none;
                  color: none;
                }
              }
              &:hover {
                background-color: #ff7233;
                color: white;
              }
            }
          }
        }
      }
    }
    .contactSubmissionUI,
    .newLetterSubmissionUI,
    .customerListUI {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        p {
          font-size: 1.2rem;
          font-weight: 500;
        }
        div {
          display: flex;
          align-items: center;
          // gap: 20px;
          label {
            font-weight: 500;
            margin-right: 10px;
            font-size: 0.9rem;
          }
          input {
            height: 30px;
            cursor: pointer;
          }
          .refreshIcon {
            margin-left: 20px;
            height: 30px;
            font-size: 20px;
            padding: 3px 10px;
            cursor: pointer;
            color: #ff7233;
            border: 1px solid #ffdccc;
            padding: 2px 15px;
            &:hover {
              background-color: #ff7233;
              color: white;
            }
          }
        }
      }
      .content {
        overflow: auto;
        table {
          margin: auto;
          margin-bottom: 20px;
          width: 100%;
        }
        table,
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
          padding: 10px 5px;
          text-align: left;
          // min-width: fit-content;
        }
        td {
          &.message {
            text-align: justify;
          }
        }
      }
    }
  }
  .loadingContainer {
    .loadingImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .dashboard {
    padding-top: 100px;
  }
}
@media screen and (max-width: 650px) {
  .dashboard {
    padding: 100px 1rem 1em 1rem;
    .top {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      .card {
        padding: 0.5rem;
      }
    }
    .bottom {
      .formSubmissionUI {
        .header {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
        .content {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 1rem;
        }
      }
      .contactSubmissionUI,
      .newLetterSubmissionUI,
      .customerListUI {
        .header {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
          div {
            .refreshIcon {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
