.toolsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem 5rem;
  .top {
    text-align: center;
    .header {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 0.5rem;
      font-family: "Nunito", sans-serif;
    }
    .subHeader {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
  }
  .middle {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    .tool {
      border: 1px solid #673ab7;
      &.bLoans {
        border: 1px solid #4caf50;
      }
      &.dFinance {
        border: 1px solid #002d72;
      }
      &.sRepo {
        border: 1px solid #ff7233;
      }
      &.reBridging {
        border: 1px solid #673ab7;
      }
      &.home1 {
        border: 1px solid #4caf50;
        border-radius: 10px;
      }
      &.home2 {
        border: 1px solid #673ab7;
        border-radius: 10px;
      }
      &.home3 {
        border: 1px solid #002d72;
        border-radius: 10px;
      }

      padding: 2rem 2rem 1rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      .header {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        .head {
          font-weight: bold;
          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
          &.reBridging {
            color: #673ab7;
          }
          &.home1 {
            color: #4caf50;
            font-size: 1.4rem;
            display: flex;
            text-align: center;
          }
          &.home2 {
            color: #673ab7;
            font-size: 1.4rem;
            text-align: center;
          }
          &.home3 {
            color: #002d72;
            font-size: 1.4rem;
            text-align: center;
          }

        }
        img {
          height: 90px;
          width: 90px;
        }
      }

      .subHead {
        flex: 1;
      }
      .link {
        font-weight: bold;
        text-decoration: none;
        color: #4caf50;
        background-color: #b7dfb9;
        border: 1px solid #4caf50;

        &.bLoans {
          color: #4caf50;
          background-color: #b7dfb9;
          border: 1px solid #4caf50;
        }
        &.dFinance {
          color: #002d72;
          border: 1px solid #002d72;
          background-color: #d2e4ff;
        }
        &.sRepo {
          color: #ff7233;
          border: 1px solid #ff7233;
          background-color: #ffdccc;
        }
        &.reBridging {
          color: #673ab7;
          border: 1px solid #673ab7;
          background-color: #d1c4e9;
        }
        &.home1 {
          color: white;
          background-color: #4caf50;
          border-radius: 5px;
     
        
        }
        &.home2 {
          color: white;
          background-color: #673ab7;
          border: 1px solid #673ab7;
          border-radius: 5px;
         
        }
        &.home3 {
          color: white;
          background-color: #002d72;
          border: 1px solid #002d72;
          border-radius: 5px;
      
        }
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
      }
    }
  }
  .bottom {
    .allToolsLink {
      color: white;
      text-decoration: none;
      padding: 0.5rem 2rem;
      font-size: 18px;
      background-color: #4caf50;
      border: 1px solid #4caf50;
      border-radius: 5px;
      &:hover {
        background-color: white;
        color: #4caf50;
      }
      &.bLoans {
        background-color: #4caf50;
        border: 1px solid #4caf50;
        &:hover {
          background-color: white;
          color: #4caf50;
        }
      }
      &.dFinance {
        background-color: #002d72;
        border: 1px solid #002d72;
        &:hover {
          background-color: white;
          color: #002d72;
        }
      }
      &.sRepo {
        background-color: #ff7233;
        border: 1px solid #ff7233;
        &:hover {
          background-color: white;
          color: #ff7233;
        }
      }
      &.reBridging {
        background-color: #673ab7;
        border: 1px solid #673ab7;
        &:hover {
          background-color: white;
          color: #673ab7;
        }
      }
      &.home1 {
        background-color: #4caf50;
      }
      &.home2 {
        background-color: #673ab7;
      }
      &.home3 {
        background-color: #002d72;
      }
      // &:hover {
      //   background-color: #c9e7cb;
      //   color: black;
      // }
    }
  }
}
@media screen and (max-width: 900px) {
  .toolsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem 2rem;
    .top {
      text-align: center;
      .header {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .subHeader {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }
    .middle {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;

      .tool {
        border: 1px solid #4caf50;
        &.bLoans {
          border: 1px solid #4caf50;
        }
        &.dFinance {
          border: 1px solid #002d72;
        }
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        .header {
          .head {
            font-weight: bold;
            display: flex;
          }
        }

        .subHead {
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          background-color: #b7dfb9;
          border: 1px solid #4caf50;
          &.bLoans {
            color: #4caf50;
            background-color: #b7dfb9;
            border: 1px solid #4caf50;
          }
          &.dFinance {
            color: #002d72;
            border: 1px solid #002d72;
            background-color: #c2daff;
          }
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
        }
      }
    }
    .bottom {
      .allToolsLink {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1.5rem;
        border: 1px solid #4caf50;
        background-color: #4caf50;
        &.bLoans {
          border: 1px solid #4caf50;
          background-color: #4caf50;
        }
        &.dFinance {
          background-color: #002d72;
          border: 1px solid #002d72;
        }
        // &:hover {
        //   background-color: #c9e7cb;
        //   color: black;
        // }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .toolsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem 1rem;
    .top {
      text-align: center;
      .header {
        font-size: 1.8rem;
        font-weight: bolder;
        font-family: "Nunito", sans-serif;
        margin-bottom: 0.5rem;
      }
      .subHeader {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }
    .middle {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;

      .tool {
        border: 1px solid #4caf50;
        &.bLoans {
          border: 1px solid #4caf50;
        }
        &.dFinance {
          border: 1px solid #002d72;
        }
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .header {
          .head {
            font-weight: bold;
          }
        }

        .subHead {
        }
        .link {
          text-decoration: none;
          color: #4caf50;
          background-color: #b7dfb9;
          border: 1px solid #4caf50;
          &.bLoans {
            color: #4caf50;
            background-color: #b7dfb9;
            border: 1px solid #4caf50;
          }
          &.dFinance {
            color: #002d72;
            border: 1px solid #002d72;
            background-color: #c2daff;
          }
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
        }
      }
    }
    .bottom {
      .allToolsLink {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1.5rem;
        border: 1px solid #4caf50;
        background-color: #4caf50;
        &.bLoans {
          border: 1px solid #4caf50;
          background-color: #4caf50;
        }
        &.dFinance {
          background-color: #002d72;
          border: 1px solid #002d72;
        }
        // &:hover {
        //   background-color: #c9e7cb;
        //   color: black;
        // }
      }
    }
  }
}
