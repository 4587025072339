.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: hsla(247, 248%, 248, 0.35);
  transition: opacity 500ms;

  // background: hsla(30, 98%, 51%, 0.687);
  // background: #ff7233;
  // background:rgba(255, 255, 255, 0.2);
  // backdrop-filter: blur(4px);
  // box-shadow: 0 15px 25px I]rgba(e,8,8,0.05);
  // border: 1px solid Ergba(255, 255, 255, 8.25);
  // border-top: 1px solid Irgba(255, 255, 255, 0.5);
  // border-Left: 1px solid Urgba(255, 255, 255, 8.5);
  // border-radius: 20px;
  // padding: 60px 20px;

  .signInPage {

      margin: 8rem auto;
      padding: 2.5rem 2rem;
      border-radius: 20px;
      width: 25%;
      position: relative;
      width: 25%;

      // margin: 110px auto;
      // padding: 2.5rem 2rem;
      // position: relative;
      // max-width: 25%;
      // border-radius: 20px;
      // font-size: 18px;

      // background: #fff;
      // border-radius: 5px;
      // transition: all 5s ease-in-out;
      // width: 25%;

      background: #fafafa;
      backdrop-filter: blur(4px);
      box-shadow: 25px 25px 75px rgba(0,0,0,0.15), 10px 10px 70px rgba(0, 0, 0, 0.15),inset -5px -5px 15px rgba(0, 0, 0, 0.15), inset 5px 5px 15px rgba(0, 0, 0, 0.05)
    }

    // border: 1px solid rgba(255, 255, 255, 0.25);
    // border-top: 1px solid rgba(255, 255, 255, 0.5);
    // border-Left: 1px solid rgba(255, 255, 255, 0.5);
    // border-radius: 20px;

    // margin: 3rem auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // border: 1px solid black;

    .crossIcon {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 12px 12px 8px 12px;
      border-radius: 50%;
      .icon {
        font-size: 15px;
        color: #3a57e8;
        cursor: pointer;
      }
    }
    .form {
      display: flex;
      justify-content: center;
      align-items: center;

      .formContainer {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        // padding: 0 3rem;
        .head {
          font-size: 22px;
          font-weight: 400;
          text-align: center;
          margin-bottom: 1.5rem;
        }
        .inputBox {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          position: relative;
          input,
          button {
            box-sizing: border-box;
            outline: none;
            height: 30px;
          }
          input {
            margin-bottom: 1rem;
            background-color: #f1f3ff;
            border: 1px solid #f1f3ff;
            border-radius: 8px;
            height: 35px;
            font-size: 16px;
            box-shadow: inset 18px 18px 30px rgba(241, 243, 255, 1), inset -18px -18px 30px rgba(241, 243, 255, 1);
          }
          .passwordVisibility {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 30px;
            .visibilityIcon {
              font-size: 20px;
            }
          }
        }

        .signUpConatiner {
          text-align: center;
          padding: 1rem 0 1rem 0;
          .signUpBtn {
            color: #3a57e8;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .otherSignUpContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          text-align: center;
          gap: 1rem;
          width: 100%;
          padding-bottom: 1.5rem;
          .continueWith {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            span {
              height: 2px;
              width: 100px;
              background-color: #c8cbd7;
            }
          }
          .icons {
            // display: flex;
            // gap: 1rem;
            // justify-content: center;
            p {
              background-color: white;
              border-radius: 10px;
              // border: 1px solid black;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.4rem 1rem;
              cursor: pointer;

              box-sizing: border-box;
              width: 100%;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              .icon {
                font-size: 2rem;
                margin-right: 10px;

                &.fb {
                  color: #4267b2;
                }
              }
              span {
                color: #3a57e8;

                font-weight: bold;
              }
            }
          }
        }
        .checkBoxContainer {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          margin-bottom: 1rem;
          .left {
            display: flex;
            input[type="checkbox"] {
              height: 15px;
              margin-right: 5px;
              cursor: pointer;
            }
            label {
              font-size: 0.9rem;
            }
          }
          .right {
            color: #3a57e8;
            font-size: 0.9rem;
            cursor: pointer;
          }
        }
        input {
          padding-left: 10px;
        }
        button {
          color: #3a57e8;
          height: 40px;
          margin: auto;
          font-size: 20px;
          font-weight: bold;
          width: 100%;
          background-color: white;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: transparent;
          cursor: pointer;
          // box-sizing: border-box;
           // padding: 0.4rem 1rem;
          box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        }

        .loadingContainer {
          .loadingImg {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  
  @media screen and (max-width: 1000px) {
    .signInPage {
      width: 50%;
    }
  }
  @media screen and (max-width: 600px) {
    .signInPage {
      width: 75%;
      margin-top: 7rem;
      .form {
        .formContainer {
          .otherSignUpContainer {
            .continueWith {
              font-size: 13px;
              span {
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .signInPage {
      // margin-top: 10rem;
      width: 70%;
      .form {
        .formContainer {
          .loadingContainer {
            height: 0px;
            .loadingImg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
