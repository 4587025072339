.testimonialsContainer {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .header {
    font-weight: bolder;
    font-size: 2rem;
    text-align: center;
    font-family: "Nunito", sans-serif;
    margin-bottom: 1.5rem;
  }
  .testimonies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;

    .testimony {
      padding: 2rem 3rem 1rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      gap: 3rem;
      background-color: #c9e7cb;
      &.bLoans {
        background-color: #c9e7cb;
      }
      &.dFinance {
        background-color: #cce0ff;
      }
      &.sRepo {
        background-color: #ffdccc;
      }
      &.reSources {
        background-color: #e1d8f1;
      }

      .text {
        line-height: 1.7;

        .leftIcon,
        .rightIcon {
          font-size: 0.9rem;
          position: relative;
          top: -5px;
        }
      }
      .customerName {
        font-weight: bold;
      }
    }
  }
  .allReviewsLink {
    text-decoration: none;
    color: white;
    background-color: #4caf50;
    border: 1px solid #4caf50;
    &:hover {
      background-color: white;
      color: #4caf50;
    }
    &.bLoans {
      border: 1px solid #4caf50;
      background-color: #4caf50;
      &:hover {
        background-color: white;
        color: #4caf50;
      }
    }
    &.dFinance {
      border: 1px solid #002d72;
      background-color: #002d72;
      &:hover {
        background-color: white;
        color: #002d72;
      }
    }
    &.sRepo {
      border: 1px solid #ff7233;
      background-color: #ff7233;
      &:hover {
        background-color: white;
        color: #ff7233;
      }
    }
    &.reSources {
      border: 1px solid #673ab7;
      background-color: #673ab7;
      &:hover {
        background-color: white;
        color: #673ab7;
      }
    }
    padding: 0.5rem 1.5rem;
    width: fit-content;
    display: block;
    margin: auto;
  }
}
@media screen and (max-width: 900px) {
  .testimonialsContainer {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .header {
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .testimonies {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;

      .testimony {
        padding: 2rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #c9e7cb;
        &.bLoans {
          background-color: #c9e7cb;
        }
        &.dFinance {
          background-color: #cce0ff;
        }

        .text {
          line-height: 1.7;

          .leftIcon,
          .rightIcon {
            font-size: 0.9rem;
            position: relative;
            top: -5px;
          }
        }
        .customerName {
          font-weight: bold;
        }
      }
    }
    .allReviewsLink {
      text-decoration: none;
      color: white;
      background-color: #4caf50;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      padding: 0.5rem 1.5rem;
      width: fit-content;
      display: block;
      margin: auto;
    }
  }
}
@media screen and (max-width: 500px) {
  .testimonialsContainer {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .header {
      font-weight: bold;
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .testimonies {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;

      .testimony {
        padding: 2rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        background-color: #c9e7cb;
        &.bLoans {
          background-color: #c9e7cb;
        }
        &.dFinance {
          background-color: #cce0ff;
        }

        .text {
          line-height: 1.7;

          .leftIcon,
          .rightIcon {
            font-size: 0.9rem;
            position: relative;
            top: -5px;
          }
        }
        .customerName {
          font-weight: bold;
        }
      }
    }
    .allReviewsLink {
      text-decoration: none;
      color: white;
      background-color: #4caf50;
      &.bLoans {
        background-color: #4caf50;
      }
      &.dFinance {
        background-color: #002d72;
      }
      padding: 0.5rem 1.5rem;
      width: fit-content;
      display: block;
      margin: auto;
    }
  }
}
