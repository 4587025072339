.uploadedDocuments {
  .header {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .header1 {
    font-size: 1rem;

    background-color: #3a57e8;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: fit-content;
    margin: 1rem auto;
  }
  .documents {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;

    .document {
      border: 1px solid #ddd;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      .head {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        * {
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .uploadedDocuments {
    .documents {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (max-width: 500px) {
  .uploadedDocuments {
    .documents {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
