.userDashBoard {
  width: 90%;
  height: max-content;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  // align-items: flex-start;
  gap: 2rem;
  .left {
    background-color: #f1f3ff;
    height: auto;
    // width: 210px;
    padding: 2rem 2rem;
    border-radius: 4px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      li {
        list-style: none;
        // padding: 2rem;
        border-radius: 4px;
        a {
          text-decoration: none;
          color: black;
          padding: 0.5rem;
          width: 100%;
          display: block;
          border-radius: 8px;

          .icon {
            position: relative;
            top: 4px;
            font-size: 20px;
            margin-right: 5px;
          }

          &.active {
            background-color: #3a57e8;
            color: white;
          }
          &:hover {
            background-color: #3a57e8;
            color: white;
            //   a {
            //     color: white;
            //     .icon {
            //       color: white;
            //     }
            //   }
          }
        }
      }
    }
  }
  .right {
    padding-top: 2rem;
    flex: 1;
  }
}
@media screen and (max-width: 1000px) {
  .userDashBoard {
    padding-top: 80px;
  }
}
@media screen and (max-width: 800px) {
  .userDashBoard {
    width: 95%;
    .left {
      padding: 1rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .userDashBoard {
    flex-direction: column;
  }
}
