.formSuccessPage{
    border: 1px solid #2463EB;
    box-shadow: 0 0 0 2px #d9dceb;
    border-radius: 8px;
    padding: 2rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    margin: auto;
    transform: translate(-50%,-50%);
    .head{
        color: #2463EB;;
        font-size: 2rem;
        font-weight: bold;
    }
    .link{
        text-decoration: none;
        color: white;
        padding: 0.75rem 2rem;
        border-radius: 10px;
        background-color: #2463EB;
        width: fit-content;
        cursor: pointer;
    }
}
@media screen and (max-width:600px) {
 .formSuccessPage{
    width: 80%;
    padding: 1rem;
    .link{
        padding: 0.5rem 2rem;
    }
 }   
}
@media screen and (max-width:400px) {
 .formSuccessPage{
   .head{
    font-size: 1.2rem;
   }
 }   
}