.answerBlock {
  background-color: #2463eb;
  padding: 1rem 2rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0.8rem 0 auto auto;
  border-radius: 20px 20px 0px 20px;
  position: relative;

  span {
    color: white;
  }
  &.radioBoxBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    font-size: 15px;
    gap: 1rem;
    span {
      color: white;
    }
    input[type="radio"] {
      margin-right: 5px;
    }
  }
  input,
  textarea {
    outline: none;
    width: 80%;
    padding: 0.5rem 0.6rem 0.5rem 0.4rem;
    height: 90px;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;

    &::focus {
      outline: none !important;
    }
  }
  &::after {
    background-image: url("../../../../assets/images/blue-traingle.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: -18px;
    content: "";
    height: 38px;
    position: absolute;
    right: 0px;
    width: 38px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &.radioBoxBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    font-size: 15px;
    gap: 1rem;
    input[type="radio"] {
      margin-right: 5px;
    }
  }
  .answer {
    // color: white;
    // align-self: flex-end;
    // border: 1px solid black;
    padding: 0.5rem 0.6rem 0.5rem 0.4rem;
    border-radius: 10px;
    width: fit-content;
    cursor: pointer;
    width: 80%;
    box-sizing: border-box;
    height: 50px;
    border: none;
    font-size: 18px;
    &.textarea {
      height: 90px;
    }
    &.radioBox {
      width: fit-content;
      margin-right: 10px;
      // display: flex;
      flex-direction: row;
      // display: block;
      height: 17px;
      width: 17px;
      position: relative;
      top: 2px;
    }
  }
}

.money_icon_input {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  width: fit-content;

  input {
    width: 100%;

    &:focus {
      box-shadow: none;
    }
  }

  img {
    height: 13px;
    width: 13px;
    margin-left: 10px;
    margin-top: -4px;
  }
}
