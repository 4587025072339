.bLoans {
  background-color: #f6fdf6;
}
.dFinance {
  background-color: #f3f8ff;
}
.sRepo {
  background-color: #fffbf9;
}
.reBridging {
  background-color: #faf8ff;
}
