.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  // width: 90%;
  // margin: 1rem auto;
  padding: 0.5rem 5rem 1.5rem 5rem;
  .scrollToTop {
    color: #2463eb;
    position: fixed;
    bottom: 10px;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
    z-index: 999;
    .icon {
      border: 1px solid white;
      border-radius: 4px;
      color: white;
      background-color: #2463eb;
    }
  }
  .callButton {
    position: fixed;
    color: white;
    font-size: 35px;
    bottom: 10px;
    left: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;

    .top {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 1rem;
      background-color: white;
      border: 1px solid #2463eb;
      padding: 1rem;
      width: 280px;
      span {
        color: black;
        &:nth-child(1) {
          font-weight: bold;
          color: #2463eb;
          font-size: 1.2rem;
          text-align: center;
        }
        &:nth-child(2) {
          color: #2463eb;
          text-align: center;
          padding-bottom: 0.5rem;
          border-bottom: 4px solid #2463eb;
        }
      }
      input,
      button {
        height: 35px;
        border-radius: 8px;
        border: 1px solid #2463eb;
        outline: none;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      button {
        cursor: pointer;
        background-color: #2463eb;
        font-weight: bold;
        font-size: 1.1rem;
        color: white;
        &:nth-of-type(2) {
          background-color: #ff7233;
          border: 1px solid #ff7233;
        }
      }
      input {
        padding-left: 5px;
      }
    }
    .bottom {
      cursor: pointer;
      width: fit-content;
      z-index: 999;
      width: fit-content;
      p {
        width: fit-content;
      }
      .callBackText {
        z-index: 9999;
        width: fit-content;
        margin: 0.5rem 0;
        font-size: 0.8rem;
        border: 1px solid #2463eb;
        padding: 5px;
        border-radius: 5px;
        color: black;
        background-color: #ededed;
      }
      .call {
        padding: 0.3rem;
        border: 1px solid white;
        background-color: #2463eb;
        border-radius: 8px;
      }
      .cross {
        padding: 0.3rem;
        border-radius: 8px;
        border: 1px solid white;
        background-color: #2463eb;
        font-size: 33px;
        position: relative;
        left: 0px;
      }
    }
  }
  .lowestRates {
    position: fixed;
    background-color: #2463eb;
    color: white;
    border-radius: 5px;
    bottom: 18px;
    left: 80px;
    // right: 80px;
    padding: 0.3rem 1rem;
    text-align: center;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    img {
      height: 30px;
      width: 30px;
    }
  }
  .loadingImg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    z-index: 999;
  }
  &.homeHeader {
    background-color: #ffffff;
    gap: 2rem;
  }
  &.bLoans {
    background-color: #f6fdf6;
  }
  &.dFinance {
    background-color: #f3f8ff;
  }
  &.sRepo {
    background-color: #fffbf9;
  }
  &.reBridging {
    background-color: #faf8ff;
  }
  .logo {
    width: 140px;
    height: 52px;
    cursor: pointer;
    padding-top: 1.3rem;    
  }
  .mobileMenu,
  .crossMenu,
  .guideMenu {
    display: none;
  }
  .center {
    // border: 1px solid black;
    display: flex;
    gap: 2rem;
    padding-top: 1.2rem;
    a {
      text-decoration: none;
      color: #042c74;
      border-bottom: 3px solid transparent;
      font-weight: 500;
      font-size: 1.2rem;
      font-weight: bold;
      &.bLoans {
        color: #4ccb14;

        &:hover {
          border-bottom: 3px solid #4ccb14;
          color: #4ccb14;
        }
        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
      &.dFinance {
        color: #042c74;
        &:hover {
          border-bottom: 3px solid #042c74;
          color: #042c74;
        }
      }
      &.sRepo {
        color: #ff7233;
        &:hover {
          border-bottom: 3px solid #ff7233;
          color: #ff7233;
        }
      }
      &.caseStudies {
        color: #673ab7;
        &:hover {
          border-bottom: 3px solid #673ab7;
          color: #673ab7;
        }
      }
      &.homeMenu {
        display: none;
      }
    }
    .linkItems {
      position: absolute;
      background-color: #f1f1f1;
      padding: 2rem 5rem;
      z-index: 99;
      top: 90px;
      left: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem;
      .leftItemsHead,
      .centerTopHead,
      .centerBottomHead,
      .rightTopHead,
      .rightMiddleHead,
      .rightBottomHead,
      .rightEndHead {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      a {
        background-color: white;
        padding: 0.3rem;
        margin-bottom: 0.4rem;
        border-left: 3px solid #4ccb14;
        &:hover {
          background-color: #4ccb14;
          border-bottom: 3px solid #4ccb14;
          color: white;
        }
      }
      .leftItems {
        display: flex;
        flex-direction: column;
        a {
          border-left: 3px solid #4ccb14;
          &:hover {
            background-color: #4ccb14;
            border-bottom: 3px solid #4ccb14;
            color: white;
          }
        }
      }
      .centerItems {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        .centerTop {
          display: flex;
          flex-direction: column;
          a {
            border-left: 3px solid #002d72;
            &:hover {
              background-color: #002d72;
              color: white;
              border-bottom: 3px solid #002d72;
            }
          }
        }
        .centerBottom {
          display: flex;
          flex-direction: column;
          a {
            border-left: 3px solid #673ab7;
            &:hover {
              background-color: #673ab7;
              color: white;
              border-bottom: 3px solid #673ab7;
            }
          }
        }
      }
      .rightItems {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .rightTop {
          display: flex;
          flex-direction: column;
          a {
            border-left: 3px solid #ff7233;
            &:hover {
              background-color: #ff7233;
              border-bottom: 3px solid #ff7233;
              color: white;
            }
          }
        }
        .rightMiddle {
          display: flex;
          flex-direction: column;
        }
        .rightBottom {
          display: flex;
          flex-direction: column;
        }
        .rightEnd {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .right {
    // border: 1px solid black;
    margin-top: 5px;
    display: flex;
    // justify-content: center;
    gap: 2rem;
    .userProfile {
      position: relative;
      top: 5px;
      z-index: 995;
      position: relative;
      display: inline-block;
      .top {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 2px solid transparent;
          box-shadow: 0 0 0 3px #d2d4dd;
        }
        .userName {
          display: flex;
          flex-direction: column;
          span {
            font-size: 0.9rem;
          }
        }
      }
      .bottom {
        display: none;
        position: absolute;
        background-color: #f6fdf6;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        margin-left: -0.5rem;
        a {
          padding: 0.5rem;
          display: block;
          font-size: 0.9rem;
          &:hover {
            background-color: #4ccb14;
            color: white;
          }
        }
      }
      &:hover {
        .bottom {
          display: block;
        }
      }
    }
    a {
      text-decoration: none;
      color: #042c74;
      border-bottom: 3px solid transparent;
      font-weight: 500;
      font-size: 1.1rem;
      &.bLoans {
        color: #4ccb14;
        &:hover {
          border-bottom: 3px solid #4ccb14;
          color: #4ccb14;
        }
      }
      &.dFinance {
        color: #042c74;
        &:hover {
          border-bottom: 3px solid #042c74;
          color: #042c74;
        }
      }
      &.sRepo {
        color: #ff7233;
        &:hover {
          border-bottom: 3px solid #ff7233;
          color: #ff7233;
        }
      }
      &.caseStudies {
        color: #673ab7;
        &:hover {
          border-bottom: 3px solid #673ab7;
          color: #673ab7;
        }
      }
      &.homeMenu {
        display: none;
      }
    }
    .signin {
      background-color: #002d72;
      border: 1px solid #002d72;
      padding: 0.6rem 0.9rem;
      color: white;
      position: relative;
      margin-top: 1rem;

      &:hover {
        background-color: white;
        color: black;
        border-bottom: 1px solid #002d72;
      }
    }
    .menu {
      background-color: #002d72;
      border: 1px solid #002d72;
      // display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem 1rem;
      color: white;
      margin-top: 1rem;

      // position: relative;
      // top: 5px;
      .hamMenu {
        // position: relative;
        // top: 3px;
        // font-size: 2rem;
      }
      &:hover {
        background-color: white;
        color: black;
        border-bottom: 1px solid #002d72;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 0;
    padding: 0.5rem;
    box-shadow: 0px 3px 5px #dfe4df;
    width: 100vw;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 111;

    .logo {
      padding-top: 0.7rem;

    }

    .mobileMenu {
      font-size: 35px;
      display: block;
      position: absolute;
      top: 27px;
      right: 15px;
    }
    .crossMenu {
      font-size: 30px;
      display: block;
      position: absolute;
      top: 29px;
      right: 16px;
    }
    .guideMenu {
      display: block;
    }
    .center {
      padding-top: 0.5rem;
      flex-direction: column;
      gap: 0.5rem;
      width: 50%;
      a {
        // border: 0.5px solid #ddd;
        padding: 0.3rem 1rem;
        // border-left: 3px solid #002d72;
        width: 100%;
        border-radius: 5px;
        // color: white;
        &:hover {
          background: #002d72;
          color: white;
        }
        &.homeMenu {
          background-color: #002d72;
          display: block;
          color: white;
        }
        &.bLoans {
          // border-left: 3px solid #4ccb14;
          background-color: #4ccb14;
          color: white;
          &:hover {
            background: #4ccb14;
            color: white;
          }
        }
        &.dFinance {
          // border-left: 3px solid #042c74;
          background-color: #042c74;
          color: white;
          &:hover {
            background: #002d72;
            color: white;
          }
        }
        &.sRepo {
          // border-left: 3px solid #ff7233;
          background-color: #ff7233;
          color: white;
          &:hover {
            background: #ff7233;
            color: white;
          }
        }
        &.caseStudies {
          // border-left: 3px solid #673ab7;
          background-color: #673ab7;
          color: white;
          &:hover {
            background: #673ab7;
            color: white;
          }
        }

        &.guideMenu {
          // border-left: 3px solid #002d72;
          background-color: #2463eb;
          color: white;
          &:hover {
            background: #002d72;
            color: white;
          }
        }
      }
    }
    .right {
      .signin {
        width: fit-content;
        background-color: transparent;
        border: none;
        padding: 0;
        color: #002d72;
        position: static;
        font-weight: normal;
        font-size: 16px;
        padding: 0.3rem 1rem;
        background: #3a57e8;
        color: white;
        &:hover {
          // background-color: transparent;
          // color: white;
          border-bottom: none;
        }
      }
    }
    .menu {
      display: none;
    }
  }
}
@media screen and (max-width: 500px) {
  .header {
    .center {
      width: 90%;
      a {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
}
