.contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .section1 {
    padding: 2rem 5rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    .left {
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      .form {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        gap: 1rem;
        .header {
          font-size: 1.5rem;
          font-weight: bold;
        }
        .subHead {
          width: 80%;
          text-align: justify;
        }
        input,
        textarea {
          width: 80%;
          padding-left: 5px;
          box-sizing: border-box;
          outline: none;
          border-radius: 7px;
        }
        input {
          height: 30px;
        }
        textarea {
          padding-top: 5px;
          border-radius: 10px;

        }
        input[type="button"] {
          border: none;
          cursor: pointer;
          font-size: 1.1rem;
          background-color: #ffdccc;
          border: 1px solid #ff7233;
          border-radius: 5px;
          &:hover {
            background-color: #ff7233;
            color: white;
          }
        }
      }
    }
    .right {

        display: flex;
        height: 450px;
        width:70%
      
    }
  }
  .section2 {
    padding: 2rem 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    .card {
      border: 1px solid rgb(239, 222, 222);
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        background-color: #ffdccc;
      }
      a {
        .icon {
          font-size: 2.5rem;
          color: black;
        }
      }
    }
  }
  .loadingImg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .contact {
    padding-top: 120px;
    .section1 {
      padding: 1rem;
      gap: 0;

      .right{
        margin-top: 2rem;
        width: 80%;
        height: 50%;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .contact {
    .section1 {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 90%;
      .left{
        padding-left: 8rem;
      }
    }
    .section2 {
      padding: 1rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .contact {
    .section1 {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 90%;
      .left {
        padding-left: 1rem;
      .right{
        height: 50%;
      }  
        .form {
          input,
          textarea,
          .subHead {
            width: 100%;
          }
        }
      }
    }
    .section2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
