.center {
  padding: 0rem 2rem 2rem 2rem;
  box-sizing: border-box;
  // border: 1px solid #2463eb;
  margin-left: calc(17.5vw + 10px);
  width: 55vw;
  border-left: solid #2463eb;
  border-right: solid #2463eb;
}

.button_group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  background: #673ab7;
  color: #fff;
}

.button {
  padding: 0.5rem 1.5rem;
  background: #ff7232;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  margin-right: 2em;

  &:disabled {
    background: grey;
  }
}

.question {
  // border: 1px solid black;
  padding: 0.5rem 1.5rem;
  border-radius: 0px 20px 20px 0px;
  width: fit-content;
  align-self: flex-start;
  background-color: #4caf50;
  color: white;
  position: relative;
  width: 80%;
  box-sizing: border-box;
  min-height: 80px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 20px;
  // justify-content: center;

  &.section1 {
    background-color: #ff7232;
  }
  &.section2 {
    background-color: #673ab7;
  }
  &.section3 {
    background-color: #4caf50;
  }
  &.section4 {
    background-color: #002d72;
  }
  &.section5 {
    background-color: #f8ab17;
  }

  &::before {
    background-image: url("../../assets/images/sum.png");
    // background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: 35px !important;
    content: "" !important;
    height: 68px;
    left: -4% !important;
    position: absolute !important;
    top: -20px !important;
    width: 68px;
  }
  &::after {
    background-image: url("../../assets/images/Green-traingle.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: -18px;
    content: "";
    height: 38px;
    left: 0px;
    position: absolute;
    width: 38px;
  }

  &.section1::after {
    background-image: url("../../assets/images/Orange-traingle.svg");
  }
  &.section2::after {
    background-image: url("../../assets/images/Purple-traingle.svg");
  }
  &.section3::after {
    background-image: url("../../assets/images/Green-traingle.svg");
  }
  &.section4::after {
    background-image: url("../../assets/images/Dark_Blue-traingle.svg");
  }
  &.section5::after {
    background-image: url("../../assets/images/yellow-traingle.svg");
  }
}

.tooltip_icon {
  height: 25px;
  width: 25px;
  fill: #fff;
  cursor: pointer;
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 80%;
  margin: 3rem auto;
}

.banner {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #ff7232;
  border-radius: 100px;
  margin-bottom: 1rem;

  span {
    width: 20%;
    max-width: 250px;
  }
}

.answerFlexBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 0 1rem;
  }
}

.grid {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    display: block;
  }

  .gridBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-weight: bold;
      font-size: 1rem;

      @media screen and (max-width: 1300px) {
        margin-top: 1rem;
      }
    }
  }

  div {
    margin-right: 20px;
  }

  @media screen and (max-width: 640px) {
    display: block;
  }
}

.formPage {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 6fr 3fr;
  grid-gap: 1rem;
  // background-color: white;
  background-image: linear-gradient(
    90deg,
    #e6faee,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #e6faee
  );
  min-height: 100vh;
  .finalResults {
    display: none;
  }
  .left {
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 2rem;
    padding-left: 1rem;
    align-items: center;
    // padding-left: 1rem;
    position: fixed;
    top: 170px;
    left: 10px;
    width: 16vw;
    min-height: 100vh;
    // top: ;

    img {
      max-width: 145px;
      height: 65px;
    }
    ul {
      padding-left: 2px;
      li {
        list-style: none;
        margin-bottom: 1rem;
        cursor: pointer;
        a {
          text-decoration: none;
          color: black;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 0.4rem;
          border-radius: 10px;
          // box-shadow: 0 0 0 2px;
          border: 4px solid transparent;

          div:nth-child(1) {
            display: grid;
            grid-template-columns: 4fr 1fr;
            width: 100%;
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid transparent;
            // box-shadow: 0 0 0 3px #d9dceb;
          }
        }
      }
    }
    .btns {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 85%;
      .btn1,
      .btn2 {
        border: 1px solid #2463eb;
        padding: 0.5rem 1rem;
        background-color: white;
        color: black;
        // width: 100%;
        // margin: auto;
        cursor: pointer;
        border-radius: 4px;
      }
      .btn1 {
        background-color: white;
        color: black;
      }
      .btn2 {
        background-color: #2463eb;
        color: white;
      }
    }
  }
  .center {
    padding: 0rem 2rem 2rem 2rem;
    box-sizing: border-box;
    // border: 1px solid #2463eb;
    margin-left: calc(17.5vw + 10px);
    width: 55vw;
    border-left: solid #2463eb;
    border-right: solid #2463eb;
    .section {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding-top: 4rem;
      &:nth-child(1) {
        padding-top: 0;
        margin-top: 1.7rem;
      }
      .head {
        font-size: 2rem;
        font-weight: bolder;
        border-radius: 20px;
        text-align: center;
        // padding-bottom: 2rem;
        font-family: Nunito, sans-serif;
        line-height: 1.5;
        color: white;
        flex: 1 1 100%;
        // padding-top: 1.5rem;
      }
      .banner {
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: #ff7232;
        border-radius: 100px;
        margin-bottom: 1rem;

        span {
          width: 20%;
          max-width: 250px;
        }
      }
      .png {
        width: 50px;
        height: 50px;
        margin: auto;
        display: block;
        border-radius: 50%;
        border: 0px solid transparent;
        box-shadow: 0 0 0 3px #d9dceb;
      }
      .gifContainer {
        display: flex;
        justify-content: flex-end;
        width: 40%;
        margin: auto;
        display: none;
      }
      img {
        width: 200px;
        height: 150px;
        margin: auto;
      }
      .formHeader {
        width: 100%;
        height: auto;
        margin: auto;
      }
      .text {
        width: 75%;
        font-weight: 500;
        font-size: 22px;
        padding: 1.5rem;
        margin: auto;
        background-color: #f8ab17;
        color: white;
        padding: 1.5rem 1.5rem;
        border-radius: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        // text-align: justify;
        // margin-bottom: 1.5rem;
        position: relative;
        gap: 3rem;

        .checkbox {
          // gap: 1rem;
          justify-content: distanve-between;
          position: relative;
        }

        .introGif {
          position: absolute;
          bottom: -7px;
          right: 0;
        }
      }
      .form1 {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .inputBox {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          width: 80%;
          // border: 1px solid black;
          margin: auto;
          //   border: 1px solid red;
          .question {
            // border: 1px solid black;
            padding: 0.5rem 1.5rem;
            border-radius: 0px 20px 20px 0px;
            width: fit-content;
            align-self: flex-start;
            background-color: #4caf50;
            color: white;
            position: relative;
            width: 80%;
            box-sizing: border-box;
            min-height: 80px;
            display: flex;
            align-items: center;
            width: fit-content;
            font-size: 20px;
            // justify-content: center;
            &.section1 {
              background-color: #ff7232;
            }
            &.section2 {
              background-color: #673ab7;
            }
            &.section3 {
              background-color: #4caf50;
            }
            &.section4 {
              background-color: #002d72;
            }
            &.section5 {
              background-color: #f8ab17;
            }
            border: none;
            span {
              z-index: 1;
            }
            &::before {
              background-image: url("../../assets/images/sum.png");
              // background-position: 50% 50% !important;
              background-repeat: no-repeat !important;
              background-size: 35px !important;
              content: "" !important;
              height: 68px;
              left: -4% !important;
              position: absolute !important;
              top: -20px !important;
              width: 68px;
            }
            &::after {
              background-image: url("../../assets/images/Green-traingle.svg");
              background-repeat: no-repeat;
              background-size: 100% auto;
              bottom: -18px;
              content: "";
              height: 38px;
              left: 0px;
              position: absolute;
              width: 38px;
            }
            &.section1::after {
              background-image: url("../../assets/images/Orange-traingle.svg");
            }
            &.section2::after {
              background-image: url("../../assets/images/Purple-traingle.svg");
            }
            &.section3::after {
              background-image: url("../../assets/images/Green-traingle.svg");
            }
            &.section4::after {
              background-image: url("../../assets/images/Dark_Blue-traingle.svg");
            }
            &.section5::after {
              background-image: url("../../assets/images/yellow-traingle.svg");
            }
            .infoIcon {
              position: relative;
              font-size: 1.25rem;
              top: 3px;
              margin-left: 15px;
              cursor: pointer;
              color: #ffffff;
            }
          }
          .answerBlock {
            background-color: #2463eb;
            padding: 1rem 2rem;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            width: 80%;
            margin: auto 0 auto auto;
            border-radius: 20px 20px 0px 20px;
            position: relative;
            input,
            textarea {
              font-size: 15px;
              outline: none;
            }
            &::after {
              background-image: url("../../assets/images/blue-traingle.svg") !important;
              background-repeat: no-repeat;
              background-size: 100% auto;
              bottom: -18px;
              content: "";
              height: 38px;
              position: absolute;
              right: 0px;
              width: 38px;
            }
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0;
            }
            &.radioBoxBlock {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              color: white;
              font-size: 15px;
              gap: 1rem;
              input[type="radio"] {
                margin-right: 5px;
              }
            }
            .answer {
              // color: white;
              background-color: white;
              // align-self: flex-end;
              // border: 1px solid black;
              padding: 0.5rem 0.6rem 0.5rem 0.4rem;
              border-radius: 10px;
              width: fit-content;
              cursor: pointer;
              width: 80%;
              box-sizing: border-box;
              height: 50px;
              border: none;
              font-size: 18px;
              &.textarea {
                height: 90px;
              }
              &.radioBox {
                width: fit-content;
                margin-right: 10px;
                // display: flex;
                flex-direction: row;
                // display: block;
                height: 17px;
                width: 17px;
                position: relative;
                top: 2px;
              }
            }
          }
        }
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: 5rem;
      .submitBtn,
      .saveForLater {
        text-align: center;
        width: fit-content;
        padding: 0.75rem 4rem;
        font-size: 1.5rem;
        background-color: #f8ab17;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
      }
      .saveForLater {
        display: none;
        background-color: #2463eb;
      }
    }
  }
  .right {
    padding: 1.5rem 1rem 2em;
    position: fixed;
    // top: 148px;
    top: 140px;
    right: 10px;
    width: 20vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 68vh;
    &.showCalc {
      filter: blur(5px);
      -webkit-filter: blur(5px);
    }
    .table {
      p {
        text-align: center;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid #cdd1dd;
        text-align: left;
        padding: 8px;
      }
      tr {
        td:nth-child(1) {
          width: 70%;
        }
        td:nth-child(2) {
          background-color: #2463eb;
          color: white;
          width: 30%;
        }
      }

      // tr:nth-child(even) {
      //   background-color: #2463EB;
      //   color: white;
      // }
    }
  }
  .results {
    background-color: white;
    display: block;
    position: fixed;
    bottom: 35px;

    width: 100vw;
    margin: auto;
    z-index: 99;
    padding: 0;
    padding-top: 1rem;

    .table {
      width: 90%;
      margin: auto;
      margin-bottom: 0.4rem;
      p {
        text-align: center;
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid #2463eb;
        text-align: left;
        padding: 8px;
        font-size: 0.9rem;
      }
      tr {
        td:nth-child(1) {
          width: 80%;
        }
        td:nth-child(2) {
          background-color: #2463eb;
          color: white;
          border-bottom: 1px solid white;
          text-align: center;
          width: 20%;
        }
      }

      // tr:nth-child(even) {
      //   background-color: #2463EB;
      //   color: white;
      // }
    }
  }
  .loadingContainer {
    .loadingImg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .formPage {
    padding-top: 100px;
    .center {
      border-right: solid white;
      .section {
        &:nth-child(1) {
          padding-top: 0;
          margin-top: 4.2rem;
        }

        .head {
          font-size: 1.5rem;
        }
        img {
          width: 150px;
          height: 100px;
          margin: auto;
        }
        .formHeader {
          width: 100%;
          max-width: 250px;
          height: auto;
          margin: auto;
        }
        .text {
          width: 80%;
          gap: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .formPage {
    padding: 0;
    padding-top: 100px;
    .finalResults {
      display: block;
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100vw;
      color: white;
      border-top: 2px solid white;
      background-color: #2463eb;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      z-index: 99;
      .docIcon {
        padding: 0.2rem 0.5rem;
        position: relative;
        top: 3px;
      }
    }
    .left {
      display: none;
    }
    .center {
      border-left: none;
      border-right: none;
      position: static;
      width: 100vw;
      margin: auto;
      margin-left: 0;

      .section {
        .head {
          font-size: 2rem;
        }
      }

      .btns {
        padding-bottom: 2rem;
        .saveForLater {
          display: block;
        }
      }
    }
    .right {
      display: none;
    }
  }
}
@media screen and (max-width: 550px) {
  .formPage {
    .center {
      padding: 1rem;
      .section {
        &:nth-child(1) {
          margin-top: 0.5rem;
        }
        .head {
          font-size: 1.8rem;
        }
        img {
          width: 120px;
          height: 120px;
          margin: auto;
          align-items: center;
        }
        .form1 {
          .inputBox {
            width: 100%;
            .question {
              // width: 95%;
              width: fit-content;
            }
            .answerBlock {
              width: 75%;

              &::after {
                right: 0px;
              }
            }
          }
        }

        .text {
          width: 87%;
          font-size: 17px;
          gap: 0.4rem;

          .checkbox {
            max-width: 100px;
            max-height: 90px;
          }
        }

        .gifContainer {
          display: block;
          text-align: center;
        }

        .banner {
          border-radius: 20px;
          margin-bottom: 0rem;
          padding: 1rem;
          display: block;
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 3rem 5rem;
        align-items: center;
        .submitBtn,
        .saveForLater {
          padding: 0.5rem 1rem;
          width: 100%;
        }
      }
    }
  }
  .formHeader {
    display: none;
  }
}
