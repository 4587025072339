.Deals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem 0;
  .dealsHeader {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
  .dealsSubHead {
    margin-bottom: 1rem;
    &.bLoans {
      color: #4caf50;
    }
    &.dFinance {
      color: #002d72;
    }
    &.sRepo {
      color: #ff7233;
    }
    &.reSources {
      color: #673ab7;
    }
    font-weight: bold;
  }
  .dealsList {
    .dealItem {
      img {
        position: relative;
        top: 5px;
        margin-right: 5px;
      }
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}
@media screen and (max-width: 550px) {
  .Deals {
    padding: 2rem 1rem;
  }
}
