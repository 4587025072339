.faqsSecondary {
  line-height: 1.625;
  max-width: 1280px;
  margin: 0rem auto 0 auto;
  padding: 3rem 1rem 0 1rem;
}
@media (min-width: 1024px) {
  .faqsSecondary {
    padding: 3rem 2rem 0 2rem;
  }
}
.faqsSecondary .faqsHeader {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  font-family: "Nunito", sans-serif;
}
.faqsSecondary .cards {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  p {
    text-align: center;
    padding: 1rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    color: white;

    &.bLoans {
      border: 1px solid #4caf50;
      background-color: #4caf50;
      &.selected {
        background-color: white;
        color: black;
      }
      &:hover {
        background-color: #b7dfb9;
        color: black;
      }
    }
    &.dFinance {
      border: 1px solid #002d72;
      background-color: #002d72;
      &.selected {
        color: black;
        background-color: white;
      }
      &:hover {
        color: black;
        background-color: #d2e4ff;
      }
    }
    &.sRepo {
      border: 1px solid #ff7233;
      background-color: #ff7233;
      &.selected {
        color: black;
      }
      &:hover {
        background-color: #ff7233;
        background-color: #ffdccc;
        color: black;
      }
    }
    &.reBridging {
      border: 1px solid #673ab7;
      background-color: #673ab7;
      &.selected {
        color: black;
        background-color: white;
      }
      &:hover {
        background-color: #e1d8f1;
        color: black;
      }
    }
  }
}
.faqsSecondary .faqsHeader > * + * {
  margin-top: 0.75rem;
}
.faqsSecondary .faqsHeader h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #1f2937;
  font-weight: 600;
}
.faqsSecondary .faqsHeader p {
  max-width: 32rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #4b5563;
  margin-left: auto;
  margin-right: auto;
}
.faqsSecondary .faqsContainer {
  margin: 3.5rem auto 0 auto;
}
@media screen and (max-width: 1000px) {
  .faqsSecondary {
    padding-top: 120px;
  }
}
@media screen and (max-width: 650px) {
  .faqsSecondary .cards {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
}
@media screen and (max-width: 400px) {
  .faqsSecondary .cards {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  .faqsSecondary .cards p {
    padding: 0.5rem;
  }
}
