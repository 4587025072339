.formDetails {
  width: 90%;
  margin: 2rem auto;
  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    p {
      font-size: 1.2rem;
    }
    div {
      display: flex;
      gap: 1rem;

      span {
        font-size: 20px;
        cursor: pointer;
        color: #576baa;
        border: 1px solid #8d9bca;
        padding: 2px 15px;
        //   &.deleteModal {
        //     border: none;
        //     padding: 0px;
        //     &:hover {
        //       background-color: none;
        //       color: none;
        //     }
        //   }
        &:hover {
          background-color: #576baa;
          color: white;
        }
      }
    }
  }
  .bottom {
    overflow: auto;
    .tableHead {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1rem;
      .tableCaption {
        text-align: left;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
    }

    td,
    th {
      border: 1px solid #cdd1dd;
      text-align: left;
      padding: 8px;
    }
    tr {
      td:nth-child(1) {
        width: 65%;
      }
      td:nth-child(2) {
        background-color: #3a57e8;
        color: white;
        width: 35%;

        // border-bottom: 1px solid white;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .formDetails {
    padding-top: 100px;
    width: 95%;
  }
}
@media screen and (max-width: 400px) {
  .formDetails {
    .top {
      flex-direction: column;
      div {
        align-self: flex-end;
      }
    }
  }
}
