.loanGuide {
  // background-color: #97e19c;
  padding: 2rem 5rem;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
  &.bLoans {
    background-color: rgb(246, 253, 246);
  }
  &.dFinance {
    background-color: rgb(243, 248, 255);
  }
  &.reSources {
    background-color: rgb(250, 248, 255);
  }
  &.sRepo {
    background-color: #fffbf9;
  }

  .header {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  .InfoBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
    .info {
      background-color: white;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      &.bLoans {
        box-shadow: 20px 20px 30px rgba(135, 212, 122, 0.25),
          10px 10px 30px rgba(135, 212, 122, 0.25);
      }
      &.reBridging {
        box-shadow: 20px 20px 30px rgba(160, 86, 255, 0.25),
          10px 10px 30px rgba(160, 86, 255, 0.25);
      }
      &.dFinance {
        box-shadow: 20px 20px 30px rgba(204, 224, 255, 1),
          10px 10px 30px rgba(204, 224, 255, 1);
      }
      &.sRepo {
        box-shadow: 10px 10px 30px rgba(255, 220, 204, 1),
          20px 20px 30px rgba(255, 220, 204, 1);
      }
      .infoHead {
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        &.bLoans {
          color: #4caf50;
        }
        &.dFinance {
          color: #002d72;
        }
        &.sRepo {
          color: #ff7233;
        }
        &.reSources {
          color: #673ab7;
        }
      }
      .infoText {
        flex: 1;
      }
      .links {
        display: flex;
        flex-direction: column;

        a {
          font-weight: bold;
          color: black;
          text-decoration: none;
          img {
            position: relative;
            top: 5px;
            margin-right: 3px;
          }
        }
      }
      .learnMoreLink {
        width: fit-content;
        text-decoration: none;
        &.bLoans {
          color: #4caf50;
        }
        &.dFinance {
          color: #002d72;
        }
        &.sRepo {
          color: #ff7233;
        }
        &.reSources {
          color: #673ab7;
        }
      }
    }
  }
  .allGuideLink {
    text-decoration: none;
    color: white;
    &.bLoans {
      background-color: #4caf50;
      border: 1px solid #4caf50;
      &:hover {
        background-color: white;
        color: #4caf50;
      }
    }
    &.dFinance {
      background-color: #002d72;
      border: 1px solid #002d72;
      &:hover {
        background-color: white;
        color: #002d72;
      }
    }
    &.sRepo {
      background-color: #ff7233;
      border: 1px solid #ff7233;
      &:hover {
        background-color: white;
        color: #ff7233;
      }
    }
    &.reSources {
      background-color: #673ab7;
      border: 1px solid #673ab7;
      &:hover {
        background-color: white;
        color: #673ab7;
      }
    }
    padding: 0.5rem 1.5rem;
    width: fit-content;
    display: block;
    margin: auto;
  }
}
@media screen and (max-width: 900px) {
  .loanGuide {
    padding: 2rem 2rem;
    .InfoBox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      .info {
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        .infoHead {
          text-align: center;
          font-weight: bold;
          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
        }
        .infoText {
        }
        .links {
          display: flex;
          flex-direction: column;
          a {
            font-weight: bold;
            color: black;
            text-decoration: none;
            img {
              position: relative;
              top: 5px;
              margin-right: 3px;
            }
          }
        }
        .learnMoreLink {
          width: fit-content;
          text-decoration: none;
          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .loanGuide {
    .header {
      font-size: 1.2rem;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    padding: 2rem 1rem;
    .InfoBox {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      .info {
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        .infoHead {
          text-align: center;
          font-weight: bold;
          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
        }
        .infoText {
        }
        .links {
          display: flex;
          flex-direction: column;
          a {
            font-weight: bold;
            color: black;
            text-decoration: none;
            img {
              position: relative;
              top: 5px;
              margin-right: 3px;
            }
          }
        }
        .learnMoreLink {
          width: fit-content;
          text-decoration: none;
          &.bLoans {
            color: #4caf50;
          }
          &.dFinance {
            color: #002d72;
          }
        }
      }
    }
  }
}
