.documents{
    .header{
font-size: 1.8rem;
text-align: center;
margin-bottom: 2rem;
font-family: "Nunito", sans-serif;
font-weight: bold;
    }
    .content{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 0rem;
        .section{
            padding:2rem;
            display:flex;
            flex-direction: column;
            gap: 1rem;
            text-align: center;
            .sectionHead{
                // background-color: #F1F3FF;
                padding: 1.25rem;
                border-radius: 10px;
                width: 60%;
                margin: auto;
                color: white;
                font-size: 1.2rem;

            }
            .sectionContent{
                border-radius: 10px;
                border :1px solid #3A57E8;
                border-style: dashed;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                padding: 2rem;
                background-color: #F1F3FF;
                .supportText{
                    font-size: 0.8rem;
                }
                .browse{
                    font-weight: bold;
                    color: #3A57E8;
                    cursor: pointer;
                }
            }
            .uploadedFiles{
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .uploadedItem{
                    padding: 0.2rem 1rem;
                    border: 1px solid #11AF22;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.9rem;
                    .deleteIcon{
                        padding: 0.2rem;
                        font-size: 1rem;
                        color: red;
                        cursor: pointer;
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }
    }
    .footer{
        display: flex;
        justify-content: flex-end;
        padding: 2rem;
        .btn{
            font-size: 20px;
            padding: 0.6rem 3rem;
            border-radius: 5px;
            border: none;
            background-color: #3A57E8;
            color: white;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:600px) {
    .documents{
        .content{
            grid-template-columns: repeat(1,1fr);
            .section{
                padding: 1rem;
            }
        }
        .footer{
            justify-content: center;
        }
    }
}